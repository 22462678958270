/* Customize the background color of the entire table */
.ag-theme-alpine {
    background-color: #fff; /* Replace with your desired background color */
}

/* Customize the background color of the header */
.ag-header {
    color: black;
    /* background-color: #ffff; Replace with your desired header background color */
}

/* Customize the background color of the body */
.ag-body {
    background-color: #fff; /* Replace with your desired body background color */
}

/* Customize the background color of the selected rows */
.ag-row-selected {
    color: black;
    background-color: #fff; /* Replace with your desired selected row background color */
}
.ag-row-animation .ag-row.ag-after-created {
    transition: transform 0.4s, top 0.4s, height 0.4s;
    background: #fff;
    color: black;
  }
  .ag-row-animation .ag-row {
    transition: transform 0.4s, top 0.4s;
    background: #fff;
    color: black;
  }