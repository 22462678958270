.success {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 320px;
    padding: 18px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    background: #a4eb81;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: fixed;
    right: 0;
    top: 20px;
    transform: translateX(100%);
    animation: slideIn 0.5s forwards, slideOut 0.5s forwards 1.5s;
    z-index:9999;
  }
  
  .success:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  
  .success__icon {
    width: 20px;
    height: 20px;
    transform: translateY(-2px);
    margin-right: 15px;
  }
  
  .success__icon path {
    fill: #0a9100;
  }
  
  .success__title {
    font-weight: 500;
    font-size: 16px;
    color: #393A37;
  }
  
  .success__close {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;
  }
  
  .success__close path {
    fill: #ffffff;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  