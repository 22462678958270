.signupContainer-for-signup{
    width: 100%;
    height: max-content;
    background: #000;
    padding: 50px;
}
.now-signup{
    width: 70%;
    margin: auto;
    border-radius:14px ;
    background: #0f1b21;
}

@media (max-width: 1024px) {
    .signupContainer-for-signup{
        padding: 20px;
    }
    .now-signup{
        width: 100%;
    }
}