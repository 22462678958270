.course-card-dashboard{
    border: 1px solid #646464;
    width: 250px;
    margin: auto;
    border-radius: 5px;
}

.card-upper-body{
    /* background: #c579ff; */
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;  

}
.card-upper-body h2.cardIcon{
color: white;
background: #454545;
padding: 10px 14px 20px;
border-radius: 50%;
width: 50%;
margin: auto;
align-items: center;
}
.card-lower-body{
    text-align: center;
}
.card-lower-body .card-course-title{
    font-size: 20px;
    font-weight: 700;
}
.card-lower-body .card-course-enroll-btn{
    background: #7979d6;
    color: white;
    padding: 5px;
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
    font-weight: 700;
    border-radius: 5px;
}
