.contest-main-div{
   
height: 100vh;
}





.contest-top-heading{
    width: 80%;
    margin: auto;
    border-bottom: 1px solid rgb(225, 225, 225);   
}
.contest-top-heading p{
    font-size: 12px;
  padding: 12px 15px 10px;
  margin: 0px;
   
}
.contest-title{
    padding: 14px;
    border-bottom:1px solid rgb(230, 230, 230) ;
}
.contest-title h5{
   width: 80%;
   margin: auto; 
  
}
.card-area{
    width: 50%;
    margin: auto;
    margin-top: 25px;
    /* display: flex; */
}
.contest-card{
    /* background: #000; */
    margin: 14px;
   
    width: 100%;
    border: 2px solid #bbbbbb;
    border-top-left-radius: 10px;
}
.card-heading-container{
    width: 100%;
    /* background: #000; */
    padding: 14px;
    display: flex;
  
}
.card-heading-container p{
    padding: 14px;
    font-size: 20px;
    font-weight: 700;
    width: 80%;
}

.design-container{
    background:white;
    width: 15%;
}

.coding .design-container{
  
        width: 2%;
        height: 70px;
        --s: 194px; /* control the size */
        --c1: #f6edb3;
        --c2: #acc4a3;
      
        --_l: #0000 calc(25% / 3), var(--c1) 0 25%, #0000 0;
        --_g: conic-gradient(from 120deg at 50% 87.5%, var(--c1) 120deg, #0000 0);
      
        background: var(--_g), var(--_g) 0 calc(var(--s) / 2),
          conic-gradient(from 180deg at 75%, var(--c2) 60deg, #0000 0),
          conic-gradient(from 60deg at 75% 75%, var(--c1) 0 60deg, #0000 0),
          linear-gradient(150deg, var(--_l)) 0 calc(var(--s) / 2),
          conic-gradient(
            at 25% 25%,
            #0000 50%,
            var(--c2) 0 240deg,
            var(--c1) 0 300deg,
            var(--c2) 0
          ),
          linear-gradient(-150deg, var(--_l)) #55897c /* third color here */;
        background-size: calc(0.866 * var(--s)) var(--s);
  
}

.quiz .design-container{
    width: 2%;
  height: 70px;
  background: repeating-linear-gradient(
      -45deg,
      orange,
      orangered 10px,
      orange 10px,
      orangered 20px
    )
    orange;
  background-blend-mode: screen;
}

.solve-card-btn{
    background: #000;
    color: white;
    padding: 10px 10px 10px;
    margin: 10px;
}
@media (max-width: 767px) {
    .contest-top-heading{
        width: 100%;
        text-align: left;
    }
    .card-area{
        width: 80%;
    }
    .design-container{
        display: none;
    }
    .card-heading-container p {
        padding: 14px;
        font-size: 15px;
        font-weight: 700;
        width: auto;
    }
    .card-heading-container {
        justify-content: space-between;
        padding: 0;
      }
}