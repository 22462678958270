.side-pannel{
    position: fixed;
    width: 55px;
    top: 0px;
    height: 100vh;
    left: 1px;
    background: #eeecff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}
.side-pannel-content{
    margin-top: 14px;
    rotate: 270deg;
}
.side-pannel-content::first-letter{
    color: #5340ff;
    font-weight: 600;
}

/*practice-code-section */
.pacrtice-code-question-div {
  margin: auto;
  display: flex;
  margin-top: 20px;
}
.code-question {
  padding: 14px 14px 14px;
  width: 30%;
  background: #f3f3ff;
  overflow-y: auto;
  height: 100vh;
}
/* question-remainning-bar */
.question-remainning-bar{
 
  background-image: linear-gradient(to right, #5b70ff, #827afe, #9e85fe, #b691fe, #ca9efe, #c7a4ff, #c4a9ff, #c2aeff, #a8acff, #88aaff, #5fa9ff, #00a7ff);
  padding: 3px;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}
/* ------------------------------------------------------------------------------ */
/* for quiz---------------------------------------------------------------------- */
.code-question.quizMode{
  width: 100%;
}
.question-area.quizMode{
  height: max-content;
}

.options-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 15px;
}

.option-item {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  word-wrap: break-word;
}

.option-item:hover {
 
  background:#f9fcff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.option-item:focus {
  outline: none;
  box-shadow: 0 0 0 2px #007bff;
}

.option-item.selected-quiz-option {
  background-color: #7d69ff;
  color: #fff;
  transition: background-color 0.5s ease-in-out;

}


.quiz-submit-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
}

.quiz-submit-button {
  background-color: #0056b3;
}


/* ------------------------------------------------------------------------------ */
.nextprev-question-btn-div{
 
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 30px;
}
.nextprev-question-btn-div button{
  background: #a886f1;
  padding: 0px 15px;
  color: white;
  border-radius: 5px;
}
.nextprev-question-btn-div p{
  text-align: center;
}

.code-question h6 {
  border-bottom: 2px dotted #d7d7d7;
  padding: 12px;
  font-weight: 600;
}
.code-question p.question-explaination {
  padding: 12px 0px 12px;
}
.editor-area {
  width: 70%;
  padding-left: 10px;
  height: 100vh;
  overflow-y: scroll;
}
.user-code {
  width: 100%;
  padding: 14px 0px 0px;
  background: #ebe8ff;
  border-top-right-radius: 11px;
  border-top-left-radius: 11px;
}
.user-code h5 {
  padding-left: 2px;
  margin: 0px 10px 12px;
  border-left: 3px solid black;
  color: #919191;
}
.submit-btn {
  cursor: not-allowed;
  background: rgb(185, 255, 185);
}

.user-code-btnsection {
  width: 90%;

  padding: 14px 0px 14px;
  display: flex;
  justify-content: space-between;
}
.user-code-btnsection button {
  border: none;
  padding: 10px;
  background: #5340ff;
  color: white;
  border-radius: 5px;
}
.test-caese-btns button {
  background: rgb(255, 255, 255);
  color: #000;
  border: 1px solid #dfdfdf;
  margin-left: 3px;
  display: none;
}
.user-code-btnsection .submit-btn {
  cursor: pointer;
  background: #5340ff;
}
/*  */
/* #editor {
  position: relative;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
} */
/* #editor-box-id {
} */
#editor-box-id .code_output {
  display: block;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: max-content;
  padding: 14px;
}
.code_output .Internal-usercode-outerbox{
  padding: 14px;
  margin: 15px;
  border: 2px solid #dbd5ff;
  border-radius: 5px;


}
.code_output .Internal-usercode-output{
  padding-left: 30px;
}
.Internal-usercode-output.Testcase-Inputs{
  background: #f1eeff;
  width: max-content;
  border-radius: 5px;
  padding: 0px 12px;
}

/* .Internal-usercode-output.Expected-Output{ */
  /* background: #eeffef;
  width: max-content;
  border-radius: 5px;
  padding: 0px 12px; */
/* } */

.code_output .Testcase_span {
  display: block;
  width: 100%;
}
.code_output .Testcase_span h6 {
  margin: 14px;
  padding: 14;
}
.code_output .Testcase_messages {
  width: 100%;
  padding: 0px 20px;
}
.Testcase_messages .coderunnermessage {
  width: 100%;
  padding: 2px 10px 0px;
  background: #f5f5f5;
}
.editor-sucess-box {
  margin: 5px;
  background: rgb(96, 255, 87);
  padding: 12px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 5px solid rgb(0, 255, 55);
  color: rgb(31, 209, 0);
}
.editor-error-box {
  margin: 5px;
  background: rgb(255, 173, 173);
  padding: 12px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 5px solid red;
  color: red;
}
/* remove line in editor */
.ace-dracula .ace_print-margin {
  width: 0px;
}

/* language selector style */
.language-select {
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: transparent;
  color: #333;
  /* Add more styles as needed */
}

.language-select:hover {
  border-color: #666;
}

.language-select:focus {
  outline: none;
  border-color: #00bfff; /* Change color when focused */
}
.fa-solid.fa-spinner.fa-spin.fa-xl {
  margin: 26px;
}
/*  */
.quiz-submit-button.question-submited{
  background: #9e85fe;
}
/*  */
@media (max-width: 767px) {
  .side-pannel{
    display: none;
  }
  .pacrtice-code-question-div {
    display: block;
  }
  .code-question {
    width: 100%;
    text-align: left;
  }
  .editor-area {
    width: 100%;
    padding-left: 0px;
    height: 30vh;
  }
  .user-code h5 {
    padding-left: 1px;
    margin: 0px 10px 15px;
    border-left: 3px solid black;
    color: #919191;
  }

  #editor-box-id .code_output {
    display: block;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 237px;
    padding: 10px;
  }
  .code_output .Testcase_span {
    display: block;
    width: 100%;
  }
  .code_output .Testcase_span h6 {
    margin: 14px;
    padding: 14;
    text-align: left;
  }
  .code_output .Testcase_messages {
    width: 100%;
    padding: 0px;
  }

  .Testcase_messages .coderunnermessage {
    width: 100%;
    padding: 2px 10px 0px;
    background: #f5f5f5;
  }
  .code-question p.question-explaination {
    padding: 0px 0px 0px;
    text-align: left;
  }

}
