/*
@File: Landset Template Style

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************
***** App landing 
** - Custom CSS
** - Theme defalut CSS
** - Navbar  CSS
** - Banner Section CSS
** - Why Choose Section CSS
** - Features Section CSS
** - Screenshots Section CSS 
** - How Use Section CSS
** - Price Section CSS
** - Testimonial Section CSS
** - Newsletter CSS
** - Footer Section CSS

**** Sass Landing Page One
** - Banner Section CSS
** - Trusted Company CSS
** - Services Section CSS
** - Why Choose Section CSS
** - Standard Code Section CSS
** - Mobile Apps Section CSS
** - Counter Section CSS

**** Sass Landing Page Two
** - Banner Section CSS
** - Service Section CSS
** - Features Section CSS
** - Keep Update  Section CSS
** - Price  Section CSS
** - Counter  Section CSS
** - Testimonial  Section CSS
** - Newsletter  Section CSS

**** Personal Portfolio Landing Page 
** - Banner Section CSS
** - About Section CSS
** - Service Section CSS
** - Software Section CSS
** - Showcase Section CSS
** - Company Section CSS
** - Testimonial Section CSS
** - Contact Section CSS

**** Creative Agency Landing Page
** - Banner Section CSS
** - About Section CSS
** - Service Section CSS
** - Work Process Section CSS
** - Case Study Section CSS
** - Testimonial Section CSS
** - Pricing Section CSS
** - Team Section CSS
** - Newsletter Section CSS

** - Back To Top CSS
** - Preloader CSS
** - Animation CSS
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
/* Font Variables */
/* Color Variables */
body {
  color: #717477;
  font-family: "Poppins", sans-serif;
  line-height: 1.8;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

/* Defalut CSS Start */
img {
  max-width: 100%;
}

a {
  -webkit-transition: .5s;
  transition: .5s;
  text-decoration: none;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
}

a:hover {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

button {
  outline: 0 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  background-color: transparent;
}

.form-control:focus {
  -webkit-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
  color: #0f2137;
}

ul {
  list-style-type: none;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.section-title {
  max-width: 600px;
  margin: 0 auto 60px;
  text-align: center;
}

.section-title span {
  font-size: 16px;
  color: #e54f4f;
  font-weight: 700;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  text-transform: capitalize;
  margin-bottom: 15px;
  line-height: 1;
}

.section-title h2 {
  font-size: 38px;
  font-weight: 700;
  color: #343d48;
  margin-bottom: 25px;
}

.section-title p {
  margin-bottom: 0;
}

/* Defalut CSS End */
/*----- App Landing Page Start -----*/
.buy-now-btn {
  right: 20px;
  z-index: 99;
  top: 50%;
  position: fixed;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block;
  color: #ffffff;
  background-color: #82b440;
  padding: 10px 20px 10px 42px;
  -webkit-box-shadow: 0 1px 20px 1px #82b440;
          box-shadow: 0 1px 20px 1px #82b440;
  font-size: 13px;
  font-weight: 600;
}

.buy-now-btn img {
  top: 50%;
  left: 20px;
  width: 15px;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.buy-now-btn:hover {
  background-color: #03abeb;
  color: #ffffff;
}

/* Navbar CSS Start */
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
  height: auto;
  padding: 30px 0 0;
}

.navbar .navbar-nav .nav-item .nav-link {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  margin: 0 15px;
  padding: 5px 0;
  position: relative;
}

.navbar .navbar-nav .nav-item .nav-link::before {
  position: absolute;
  content: '';
  background-color: #ffffff;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transition: .5s;
  transition: .5s;
}

.navbar .navbar-nav .nav-item .nav-link:hover::before {
  width: 100%;
}

.navbar .navbar-nav .nav-item .active::before {
  width: 100%;
}

.navbar .navbar-btn a {
  font-size: 16px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  border: 1px solid #ffffff;
  padding: 10px 30px;
  border-radius: 80px;
  margin-left: 30px;
}

.navbar .navbar-btn a:hover {
  background-color: #ffffff;
  color: #079ce8;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  z-index: 999;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.36);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.36);
  background-color: #069ee8;
  -webkit-animation: .5s ease-in-out fadeInDown;
          animation: .5s ease-in-out fadeInDown;
}

/* Navbar CSS End */
/* Banner Section CSS Start */
.banner-bg-one {
  /* background-image: url(../img/app-landing/banner-bg.png); */
}

.main-banner {
  position: relative;
  background-position: center center;
  background-size: cover;
  height: 900px;
}

.main-banner .banner-text {
  max-width: 650px;
}

.main-banner .banner-text h1 {
  font-size: 60px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}

.main-banner .banner-text p {
  color: #ffffff;
  max-width: 600px;
  margin-bottom: 30px;
}

.main-banner .banner-text .banner-btn a {
  color: #03abeb;
  background: #ffffff;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 80px;
  margin-right: 30px;
  -webkit-box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
          box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
}

.main-banner .banner-text .banner-btn a:hover {
  background: #03abeb;
  color: #ffffff;
}

.main-banner .banner-text .banner-btn .banner-video {
  background: transparent;
  color: #ffffff;
  padding: 0;
  -webkit-box-shadow: 0 0 0 0 !important;
          box-shadow: 0 0 0 0 !important;
}

.main-banner .banner-text .banner-btn .banner-video i {
  font-size: 35px;
  top: 7px;
  position: relative;
  left: -5px;
}

.main-banner .banner-text .banner-btn .banner-video:hover {
  background: none;
}

.main-banner .banner-img {
  position: relative;
  z-index: 0;
}

.main-banner .banner-img img {
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.main-banner .banner-img::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/shape/home-shape-three.png); */
  background-position: center center;
  background-size: cover;
  width: 135px;
  height: 120px;
  left: 40px;
  top: -47px;
  z-index: -1;
  -webkit-animation: translateX 6s infinite linear;
          animation: translateX 6s infinite linear;
}

.main-banner .banner-img::after {
  position: absolute;
  content: '';
  background: #d3c0fa;
  width: 150px;
  height: 150px;
  border-radius: 30px;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  -webkit-animation: translateX 6s infinite linear;
          animation: translateX 6s infinite linear;
}

.main-banner .banner-img img {
  border-radius: 25px;
  max-width: 250px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.main-banner .banner-shape img {
  position: absolute;
}

.main-banner .banner-shape :nth-child(1) {
  top: 140px;
  left: -100px;
  width: 220px;
  -webkit-animation: translateY 4s infinite linear;
          animation: translateY 4s infinite linear;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.main-banner .banner-shape :nth-child(2) {
  top: 0px;
  left: -25px;
  width: 100px;
  -webkit-animation: translateY 4s infinite linear;
          animation: translateY 4s infinite linear;
}

.main-banner .banner-shape :nth-child(3) {
  right: 50px;
  top: 100px;
  -webkit-animation: translateX 6s infinite linear;
          animation: translateX 6s infinite linear;
}

/* Banner Section CSS End */
/* Why Choose Section CSS Start */
.why-choose {
  position: relative;
}

.why-choose::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/shape/pattern.png); */
  background-position: center center;
  background-size: cover;
  width: 350px;
  height: 500px;
  left: 0;
  top: 350px;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.why-choose::after {
  position: absolute;
  content: '';
  /* background-image: url(../img/map-two.png); */
  background-position: center center;
  background-size: cover;
  right: 0;
  top: 125px;
  width: 250px;
  height: 310px;
  opacity: .5;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.why-choose .section-title {
  margin: 0 0 60px;
  text-align: left;
}

.why-choose .section-title p {
  margin: 0;
}

.why-choose .why-choose-img {
  position: relative;
}

.why-choose .why-choose-img::before {
  position: absolute;
  content: '';
  width: 195px;
  height: 195px;
  background: #ffebc5;
  top: -50px;
  right: -55px;
  z-index: -1;
  border-radius: 50px;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

.why-choose .why-choose-img img {
  border-radius: 45px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  width: 100%;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.why-choose .why-choose-text .media {
  margin-bottom: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.why-choose .why-choose-text .media i {
  font-size: 35px;
  position: relative;
  color: #ffffff;
  top: 30px;
  left: 25px;
  z-index: 0;
  line-height: 1;
}

.why-choose .why-choose-text .media i::after {
  position: absolute;
  content: '';
  width: 65px;
  height: 65px;
  top: -15px;
  left: -16px;
  background: linear-gradient(-40deg, #c94776 3%, #e54f4f 100%);
  border-radius: 15px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: -1;
}

.why-choose .why-choose-text .media i.flaticon-cinema::after {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, #00b6ee), to(#0899e7));
  background: linear-gradient(0deg, #00b6ee 25%, #0899e7 100%);
}

.why-choose .why-choose-text .media i.flaticon-late::after {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, #8950e5), to(#9859fe));
  background: linear-gradient(0deg, #8950e5 25%, #9859fe 100%);
}

.why-choose .why-choose-text .media i.flaticon-wallet::after {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, #e7a01a), to(#fec454));
  background: linear-gradient(0deg, #e7a01a 25%, #fec454 100%);
}

.why-choose .why-choose-text .media .media-body {
  padding-left: 55px;
}

.why-choose .why-choose-text .media .media-body h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.why-choose .why-choose-text .why-choose-btn {
  margin-top: 60px;
}

.why-choose .why-choose-text .why-choose-btn a {
  color: #0f2137;
  border: 1px solid #0f2137;
  padding: 0 30px 0px;
  line-height: 1;
  border-radius: 50px;
  margin-right: 25px;
}

.why-choose .why-choose-text .why-choose-btn a i {
  top: 15px;
  position: relative;
  left: 0;
  font-size: 30px;
  line-height: 1;
}

.why-choose .why-choose-text .why-choose-btn a p {
  margin: 0 0 10px;
  padding-left: 40px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 0;
  top: -10px;
  position: relative;
}

.why-choose .why-choose-text .why-choose-btn a h5 {
  margin: 0;
  padding-left: 40px;
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  -webkit-transition: .5s;
  transition: .5s;
  top: -13px;
  position: relative;
}

.why-choose .why-choose-text .why-choose-btn a:hover {
  background: -webkit-gradient(linear, left bottom, left top, from(#00b7eb), to(#0898e7));
  background: linear-gradient(0deg, #00b7eb 0%, #0898e7 100%);
  color: #ffffff;
  border: 1px solid transparent;
}

.why-choose .why-choose-text .why-choose-btn a:hover h5 {
  color: #ffffff;
}

.why-choose .why-choose-text .why-choose-btn .active {
  background: -webkit-gradient(linear, left bottom, left top, from(#00b7eb), to(#0898e7));
  background: linear-gradient(0deg, #00b7eb 0%, #0898e7 100%);
  color: #ffffff;
  border: 1px solid transparent;
}

.why-choose .why-choose-text .why-choose-btn .active h5 {
  color: #ffffff;
}

/* Why Choose Section CSS End */
/* Features Section CSS Start */
.feature-section .feature-card {
  background: #ffffff;
  border-radius: 20px 0 20px 0;
  text-align: center;
  padding: 65px 40px 35px;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
          box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  margin-bottom: 30px;
  -webkit-transition: .5s;
  transition: .5s;
}

.feature-section .feature-card:hover {
  background: #0899e7;
}

.feature-section .feature-card:hover i, .feature-section .feature-card:hover h3, .feature-section .feature-card:hover p {
  color: #ffffff !important;
}

.feature-section .feature-card:hover i::after {
  background: #44b8ef;
}

.feature-section .feature-card i {
  font-size: 30px;
  color: #9356f5;
  margin-bottom: 45px;
  display: inline-block;
  position: relative;
  z-index: 0;
  -webkit-transition: .5s;
  transition: .5s;
  line-height: 1;
}

.feature-section .feature-card i::after {
  position: absolute;
  content: '';
  width: 70px;
  height: 70px;
  background: #e1d3f8;
  top: -20px;
  left: -20px;
  z-index: -1;
  -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
          clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
}

.feature-section .feature-card i.flaticon-credit-card {
  color: #ca4774;
}

.feature-section .feature-card h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  -webkit-transition: .5s;
  transition: .5s;
}

.feature-section .feature-card p {
  margin-bottom: 0;
  -webkit-transition: .5s;
  transition: .5s;
}

.feature-section .active {
  background: #0899e7;
}

.feature-section .active i, .feature-section .active h3, .feature-section .active p {
  color: #ffffff;
}

.feature-section .active i::after {
  background: #44b8ef;
}

/* Features Section CSS End */
/* Screenshots Section CSS Start */
.screenshots-section {
  background: #e2f4fe;
  position: relative;
  padding-bottom: 90px;
}

.screenshots-section .section-title {
  max-width: 600px;
}

.screenshots-section .screenshot-slider .screenshoot-img {
  margin-bottom: 30px;
}

.screenshots-section .screenshot-slider .screenshoot-img img {
  border-radius: 35px;
}

.screenshots-section .screenshot-slider .owl-stage {
  padding-top: 30px;
}

.screenshots-section .screenshot-slider .center {
  margin-top: -30px;
  -webkit-transition: 1s;
  transition: 1s;
}

.screenshots-section .screenshot-slider .center img {
  -webkit-box-shadow: 0px 12px 28px rgba(72, 73, 121, 0.1);
          box-shadow: 0px 12px 28px rgba(72, 73, 121, 0.1);
}

.screenshots-section .screenshot-slider .owl-dot span {
  width: 5px;
  height: 5px;
  background: #0a96e5 !important;
  margin: 0 3px;
  border-radius: 3px;
}

.screenshots-section .screenshot-slider .owl-dot.active {
  width: 25px;
  background: #0a96e5 !important;
  border-radius: 5px;
}

.screenshots-section .screenshot-shape img {
  position: absolute;
}

.screenshots-section .screenshot-shape :nth-child(1) {
  top: 40px;
  left: 100px;
  -webkit-animation: translateX 10s infinite linear;
          animation: translateX 10s infinite linear;
}

.screenshots-section .screenshot-shape :nth-child(2) {
  top: 200px;
  left: 150px;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.screenshots-section .screenshot-shape :nth-child(3) {
  top: 100px;
  left: 300px;
  -webkit-animation: translateX 10s infinite linear;
          animation: translateX 10s infinite linear;
}

.screenshots-section .screenshot-shape :nth-child(4) {
  right: 300px;
  top: 20px;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.screenshots-section .screenshot-shape :nth-child(5) {
  right: 400px;
  top: 270px;
  -webkit-animation: translateX 10s infinite linear;
          animation: translateX 10s infinite linear;
}

.screenshots-section .screenshot-shape :nth-child(6) {
  right: 100px;
  top: 100px;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

/* Screenshots Section CSS End */
/* How Use Section CSS Start */
.how-use {
  position: relative;
}

.how-use::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/map-two.png); */
  background-position: center center;
  background-size: cover;
  width: 190px;
  height: 250px;
  top: 250px;
  left: 0;
  opacity: .5;
}

.how-use .how-use-card {
  text-align: center;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
          box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  border-radius: 20px;
  position: relative;
  padding: 45px 30px;
  margin-bottom: 30px;
  background: #ffffff;
}

.how-use .how-use-card span {
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 30px;
  display: inline-block;
  font-weight: 700;
}

.how-use .how-use-card i {
  font-size: 60px;
  display: inline-block;
  margin-bottom: 15px;
  line-height: 1;
}

.how-use .how-use-card h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
}

.how-use .how-card-one span {
  color: #f6c2c2;
}

.how-use .how-card-one i {
  background: -webkit-gradient(linear, left bottom, left top, from(#ca4775), to(#e54f50));
  background: linear-gradient(to top, #ca4775 0%, #e54f50 100%);
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.how-use .how-card-two span {
  color: #f8e0b3;
}

.how-use .how-card-two i {
  background: -webkit-gradient(linear, left bottom, left top, from(#e7a019), to(#fdc251));
  background: linear-gradient(to top, #e7a019 0%, #fdc251 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
}

.how-use .how-card-three span {
  color: #9959fe;
}

.how-use .how-card-three i {
  background: -webkit-gradient(linear, left bottom, left top, from(#8a50e5), to(#9959ff));
  background: linear-gradient(to top, #8a50e5 0%, #9959ff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
}

.how-use .how-card-four span {
  color: #a8ddf7;
}

.how-use .how-card-four i {
  background: -webkit-gradient(linear, left bottom, left top, from(#01b4ed), to(#0899e7));
  background: linear-gradient(to top, #01b4ed 0%, #0899e7 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
}

.how-use .how-use-slider {
  position: relative;
  z-index: 0;
}

.how-use .how-use-slider::before {
  position: absolute;
  content: '';
  width: 195px;
  height: 195px;
  background: #bfe8ff;
  top: -50px;
  left: -55px;
  z-index: -1;
  border-radius: 50px;
  -webkit-animation: translateX 10s infinite linear;
          animation: translateX 10s infinite linear;
}

.how-use .how-use-slider::after {
  position: absolute;
  content: '';
  /* background-image: url(../img/shape/pattern-two.png); */
  background-position: center center;
  background-size: cover;
  width: 285px;
  height: 224px;
  right: -50px;
  bottom: 105px;
  z-index: -1;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.how-use .how-use-slider img {
  border-radius: 45px;
  width: 100%;
}

.how-use .how-use-slider .owl-dot span {
  width: 5px;
  height: 5px;
  background: #0a96e5 !important;
  margin: 0 3px;
  border-radius: 3px;
}

.how-use .how-use-slider .owl-dot.active {
  width: 25px;
  background: #0a96e5 !important;
  border-radius: 5px;
}

/* How Use Section CSS End */
/* Price Section CSS Start */
.pricing-section {
  padding-top: 70px;
}

.pricing-section .plan-list ul {
  padding-left: 0;
  margin-bottom: 60px;
  border: 0;
  display: inline-block;
}

.pricing-section .plan-list ul li {
  display: inline-block;
}

.pricing-section .plan-list ul li a {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #0898e7;
  padding: 15px 35px;
  border: 1px solid #0898e7;
  cursor: pointer;
  border-radius: 50px;
  display: inline-block;
  margin: 0 5px;
  -webkit-transition: .5s;
  transition: .5s;
}

.pricing-section .plan-list ul li a:hover {
  background: #0898e7;
  color: #ffffff;
  border: 1px solid transparent;
}

.pricing-section .plan-list ul li .active {
  background: #0898e7;
  color: #ffffff;
  border: 1px solid transparent;
}

.pricing-section .price-card {
  padding: 50px;
  border: 1px solid #069fe7;
  border-radius: 30px;
  text-align: center;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
          box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  -webkit-transition: .5s;
  transition: .5s;
  margin-bottom: 30px;
}

.pricing-section .price-card:hover {
  background: #05a3ea;
}

.pricing-section .price-card:hover p, .pricing-section .price-card:hover h3, .pricing-section .price-card:hover .price-feature ul li, .pricing-section .price-card:hover .price-feature ul li i {
  color: #ffffff;
}

.pricing-section .price-card p {
  color: #343d48;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
  margin-bottom: 10px;
  -webkit-transition: .5s;
  transition: .5s;
}

.pricing-section .price-card h3 {
  font-size: 36px;
  font-weight: 700;
  color: #0898e7;
  margin-bottom: 30px;
  -webkit-transition: .5s;
  transition: .5s;
}

.pricing-section .price-card h3 span {
  font-size: 15px;
  font-weight: 400;
}

.pricing-section .price-card .price-feature {
  text-align: left;
}

.pricing-section .price-card .price-feature ul {
  margin-bottom: 40px;
  line-height: 1;
  margin-top: 30px;
  padding-left: 30px;
}

.pricing-section .price-card .price-feature ul li {
  font-size: 18px;
  color: #343d48;
  margin-bottom: 20px;
  -webkit-transition: .5s;
  transition: .5s;
  display: block;
}

.pricing-section .price-card .price-feature ul li i {
  color: #32cd32;
  -webkit-transition: .5s;
  transition: .5s;
  font-size: 14px;
  margin-right: 5px;
}

.pricing-section .price-card .price-feature ul :nth-child(5), .pricing-section .price-card .price-feature ul :nth-child(6), .pricing-section .price-card .price-feature ul :nth-child(7), .pricing-section .price-card .price-feature ul :nth-child(8) {
  opacity: .5;
}

.pricing-section .price-card .price-feature ul :last-child {
  margin-bottom: 0;
}

.pricing-section .price-card .price-btn a {
  font-size: 16px;
  padding: 20px 40px;
  color: #03abeb;
  background: #ffffff;
  font-weight: 600;
  border-radius: 50px;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
          box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}

.pricing-section .business-class .price-feature ul :nth-child(5), .pricing-section .business-class .price-feature ul :nth-child(6) {
  opacity: 1;
}

.pricing-section .business-class .price-feature ul :nth-child(7), .pricing-section .business-class .price-feature ul :nth-child(8) {
  opacity: .5;
}

.pricing-section .premium .price-feature ul :nth-child(5), .pricing-section .premium .price-feature ul :nth-child(6), .pricing-section .premium .price-feature ul :nth-child(7), .pricing-section .premium .price-feature ul :nth-child(8) {
  opacity: 1;
}

.pricing-section .active-price {
  background: #05a3ea;
}

.pricing-section .active-price p, .pricing-section .active-price h3, .pricing-section .active-price .price-feature ul li, .pricing-section .active-price .price-feature ul li i {
  color: #ffffff;
}

/* Price Section CSS End */
/* Testimonial Section CSS Start */
.testimonial-section {
  position: relative;
  padding-bottom: 100px;
}

.testimonial-section .testimonial-img {
  position: relative;
}

.testimonial-section .testimonial-img .client-img img {
  border-radius: 50%;
  position: absolute;
  -webkit-animation: pulse 4s infinite linear;
          animation: pulse 4s infinite linear;
}

.testimonial-section .testimonial-img .client-img :nth-child(1) {
  top: 95px;
  left: 120px;
}

.testimonial-section .testimonial-img .client-img :nth-child(2) {
  bottom: 120px;
  left: 28%;
}

.testimonial-section .testimonial-img .client-img :nth-child(3) {
  top: 60px;
  left: 35%;
}

.testimonial-section .testimonial-img .client-img :nth-child(4) {
  left: 50%;
  top: 140px;
  max-width: 100px;
}

.testimonial-section .testimonial-img .client-img :nth-child(5) {
  right: 20%;
  top: 55px;
  width: 55px;
}

.testimonial-section .testimonial-img .client-img :nth-child(6) {
  right: 95px;
  bottom: 80px;
}

.testimonial-section .testimonial-slider {
  position: relative;
}

.testimonial-section .testimonial-slider::before {
  position: absolute;
  content: '\f110';
  font-size: 130px;
  font-family: Flaticon;
  top: -77px;
  right: 85px;
  color: #f7f7f7;
}

.testimonial-section .testimonial-slider .testimonial-item {
  padding: 45px 90px 45px 0;
}

.testimonial-section .testimonial-slider .testimonial-item img {
  margin-bottom: 15px;
  width: 70px;
}

.testimonial-section .testimonial-slider .testimonial-item .client-info {
  margin-bottom: 20px;
}

.testimonial-section .testimonial-slider .testimonial-item .client-info h3 {
  margin-bottom: 0;
  font-size: 20px;
  color: #0898e7;
  font-weight: 600;
}

.testimonial-section .testimonial-slider .testimonial-item .client-info span {
  font-size: 14px;
  color: #818181;
  font-family: "Open Sans", sans-serif;
}

.testimonial-section .testimonial-slider .testimonial-item p {
  max-width: 420px;
}

.testimonial-section .testimonial-slider .owl-prev, .testimonial-section .testimonial-slider .owl-next {
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 45px;
  height: 45px;
  border: 1px solid #0898e7 !important;
  font-size: 14px;
  line-height: 45px;
  border-radius: 40px;
  text-align: center;
  color: #0898e7 !important;
  -webkit-transition: .5s;
  transition: .5s;
}

.testimonial-section .testimonial-slider .owl-prev:hover, .testimonial-section .testimonial-slider .owl-next:hover {
  background: #0898e7;
  color: #ffffff !important;
}

.testimonial-section .testimonial-slider .owl-next {
  left: 60px;
}

.testimonial-section .testimonial-shape img {
  position: absolute;
}

.testimonial-section .testimonial-shape :nth-child(1) {
  top: 165px;
  right: 65px;
  -webkit-animation: translateX 5s infinite linear;
          animation: translateX 5s infinite linear;
}

.testimonial-section .testimonial-shape :nth-child(2) {
  top: 0;
  left: 100px;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

.testimonial-section .testimonial-shape :nth-child(3) {
  top: 55px;
  right: 235px;
  -webkit-animation: translateX 5s infinite linear;
          animation: translateX 5s infinite linear;
}

.testimonial-section .testimonial-shape :nth-child(4) {
  top: 400px;
  right: 10px;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

.testimonial-section .testimonial-shape :nth-child(5) {
  bottom: 55px;
  right: 200px;
  -webkit-animation: translateX 5s infinite linear;
          animation: translateX 5s infinite linear;
}

/* Testimonial Section CSS End */
/* Newsletter Section CSS End */
.newsletter {
  margin-bottom: -160px;
  z-index: 1;
  position: relative;
}

.newsletter .newsletter-area {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, #00b6ee), color-stop(50%, #0898e7));
  background: linear-gradient(0deg, #00b6ee 25%, #0898e7 50%);
  border-radius: 50px;
  padding: 70px;
  position: relative;
}

.newsletter .newsletter-area .subscribe-shape img {
  position: absolute;
}

.newsletter .newsletter-area .subscribe-shape :nth-child(1) {
  top: 0;
  right: 100px;
  -webkit-animation: translateX 10s infinite linear;
          animation: translateX 10s infinite linear;
}

.newsletter .newsletter-area .subscribe-shape :nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation: translateX 15s infinite linear;
          animation: translateX 15s infinite linear;
}

.newsletter .newsletter-area .subscribe-shape :nth-child(3) {
  top: 100px;
  right: 0;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

.newsletter .newsletter-area .newsletter-text {
  padding-right: 35px;
}

.newsletter .newsletter-area .newsletter-text h3 {
  font-size: 28px;
  color: #ffffff;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 22px;
}

.newsletter .newsletter-area .newsletter-text p {
  color: #ffffff;
  margin-bottom: 0;
}

.newsletter .newsletter-area .newsletter-form {
  position: relative;
}

.newsletter .newsletter-area .newsletter-form .form-control {
  background: #5cc5f1;
  height: 50px;
  border: 0;
  border-radius: 30px;
  padding: 0 20px;
  color: #ffffff;
}

.newsletter .newsletter-area .newsletter-form ::-webkit-input-placeholder {
  color: #ffffff;
  opacity: .9;
}

.newsletter .newsletter-area .newsletter-form :-ms-input-placeholder {
  color: #ffffff;
  opacity: .9;
}

.newsletter .newsletter-area .newsletter-form ::-ms-input-placeholder {
  color: #ffffff;
  opacity: .9;
}

.newsletter .newsletter-area .newsletter-form ::placeholder {
  color: #ffffff;
  opacity: .9;
}

.newsletter .newsletter-area .newsletter-form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  border-radius: 0 30px 30px 0;
  border: 0;
  color: #03abeb;
  background: #ffffff;
  font-weight: 600;
  padding: 0 50px;
  font-family: "Open Sans", sans-serif;
  -webkit-transition: .5s;
  transition: .5s;
  border: 1px solid transparent;
  z-index: 1;
}

.newsletter .newsletter-area .newsletter-form button:hover {
  background: #5cc5f1;
  color: #ffffff;
  border-left: 1px solid #ffffff;
}

.newsletter .newsletter-area .validation-danger {
  color: #ffffff;
}

.newsletter .newsletter-area #validator-newsletter {
  padding-left: 20px;
  margin-top: 5px;
}

/* Newsletter Section CSS End */
/* Footer Section CSS Start */
.footer-area {
  background: #069fe9;
  padding-top: 265px;
  border-radius: 60px 60px 0 0;
}

.footer-area .footer-widget .footer-logo img {
  margin-bottom: 15px;
}

.footer-area .footer-widget p {
  color: #ffffff;
  margin-bottom: 30px;
}

.footer-area .footer-widget .footer-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-area .footer-widget .footer-link a {
  margin-right: 20px;
}

.footer-area .footer-widget .footer-link a:hover img {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.footer-area .footer-widget .footer-link a img {
  -webkit-transition: .5s;
  transition: .5s;
}

.footer-area .footer-widget h3 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}

.footer-area .footer-widget ul {
  padding-left: 0;
  line-height: 1;
  margin-bottom: 0;
}

.footer-area .footer-widget ul li {
  display: block;
  margin-bottom: 15px;
  -webkit-transition: .5s;
  transition: .5s;
}

.footer-area .footer-widget ul li a {
  color: #ffffff;
  -webkit-transition: .5s;
  transition: .5s;
}

.footer-area .footer-widget ul li a:hover {
  color: #513fcb;
  margin-left: 2px;
}

.footer-area .footer-widget ul li a:hover i {
  color: #513fcb;
}

.footer-area .footer-widget ul li a i {
  font-size: 10px;
  position: relative;
  top: -2px;
}

.footer-area .footer-widget ul li i {
  font-size: 10px;
  position: relative;
  top: -2px;
  -webkit-transition: .5s;
  transition: .5s;
}

.footer-area .footer-widget ul :last-child {
  margin-bottom: 0;
}

.footer-area .pl-90 {
  padding-left: 90px;
}

.footer-area .copyright-area {
  border-top: 1px solid #42c1f1;
  padding: 20px 0;
  margin-top: 100px;
}

.footer-area .copyright-area img {
  margin-right: 10px;
}

.footer-area .copyright-area p {
  color: #ffffff;
  margin-bottom: 0;
  text-align: right;
}

.footer-area .copyright-area p a {
  color: #ffffff;
}

.footer-area .copyright-area p a:hover {
  color: #165885;
}

.footer-area .copyright-area .lh-1 {
  line-height: 1;
}

/* Footer Section CSS End */
/*----- App Landing Page End -----*/
/*----- Sass Landing Page One Start -----*/
/* Banner CSS Start */
.banner-bg-two {
  /* background-image: url(../img/sass-landing/home-bg.png); */
}

.banner-style-two {
  position: relative;
}

.banner-style-two .banner-image-two img {
  position: absolute;
}

.banner-style-two .banner-image-two :nth-child(1) {
  bottom: 80px;
  right: 0;
  max-width: 710px;
  z-index: 1;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.banner-style-two .banner-image-two :nth-child(2) {
  right: 35px;
  bottom: 17px;
  max-width: 200px;
  z-index: 2;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 20px 0 #0000003b;
          box-shadow: 0 0 20px 0 #0000003b;
  -webkit-animation: translateX 10s infinite linear;
          animation: translateX 10s infinite linear;
}

.banner-style-two .banner-image-two :nth-child(3) {
  right: 35px;
  bottom: 415px;
  max-width: 200px;
}

/* Banner CSS End */
/* Trusted Company CSS Start */
.trusted-company {
  padding-top: 75px;
}

.trusted-company .company-slider img {
  max-width: 200px;
  padding: 0 20px 20px;
}

/* Trusted Company CSS End */
/* Services CSS Start */
.service-section {
  position: relative;
  padding-top: 70px;
}

.service-section .section-title span {
  margin-bottom: 10px;
}

.service-section::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/map-two.png); */
  background-position: center center;
  background-size: cover;
  width: 250px;
  height: 250px;
  right: 0;
  top: 0;
}

.service-section .service-card {
  text-align: center;
  border-radius: 20px 0 20px 0;
  -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11) !important;
          box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11) !important;
  padding: 60px 25px 45px;
  margin-bottom: 30px;
  background: #ffffff;
  -webkit-transition: .5s;
  transition: .5s;
}

.service-section .service-card:hover {
  background: #069fe9;
}

.service-section .service-card:hover i, .service-section .service-card:hover p, .service-section .service-card:hover h3 {
  color: #ffffff !important;
}

.service-section .service-card:hover i::after {
  background: #44b9ef !important;
}

.service-section .service-card i {
  font-size: 40px;
  display: inline-block;
  line-height: 1;
  margin-bottom: 40px;
  -webkit-transition: .5s;
  transition: .5s;
  position: relative;
  z-index: 0;
}

.service-section .service-card i::after {
  position: absolute;
  content: '';
  width: 70px;
  height: 70px;
  top: -16px;
  left: -18px;
  z-index: -1;
  -webkit-transition: .5s;
  transition: .5s;
  -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
          clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
}

.service-section .service-card.service-card-one i {
  color: #8b51e8;
}

.service-section .service-card.service-card-one i::after {
  background: #e1d3f8;
}

.service-section .service-card.service-card-two i {
  color: #187b9a;
}

.service-section .service-card.service-card-two i::after {
  background: #44b9ef;
}

.service-section .service-card.service-card-three i {
  color: #cb4873;
}

.service-section .service-card.service-card-three i::after {
  background: #f8d3d3;
}

.service-section .service-card.service-card-four i {
  color: #eba624;
}

.service-section .service-card.service-card-four i::after {
  background: #f9e7c5;
}

.service-section .service-card.service-card-five i {
  color: #069fe9;
}

.service-section .service-card.service-card-five i::after {
  background: #c1e5f9;
}

.service-section .service-card.service-card-six i {
  color: #ef3dbb;
}

.service-section .service-card.service-card-six i::after {
  background: #fbceee;
}

.service-section .service-card h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  -webkit-transition: .5s;
  transition: .5s;
}

.service-section .service-card p {
  margin-bottom: 0;
  -webkit-transition: .5s;
  transition: .5s;
}

/* Services CSS End */
/* Why Choose CSS Start */
.why-choose-two::after {
  position: absolute;
  content: '';
  /* background-image: url(../img/sass-landing/why-choose-bg.png); */
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: none;
          animation: none;
}

.why-choose-two::before {
  display: none;
}

.why-choose-two .section-title {
  margin: 0 auto 60px;
}

.why-choose-two .why-choose-text .media i.flaticon-layers::after {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, #8950e5), to(#9859fe));
  background: linear-gradient(0deg, #8950e5 25%, #9859fe 100%);
}

.why-choose-two .why-choose-text .media i.flaticon-late::after {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, #00b6ee), to(#0899e7));
  background: linear-gradient(0deg, #00b6ee 25%, #0899e7 100%);
}

.why-choose-two .why-choose-img {
  z-index: 0;
}

.why-choose-two .why-choose-img::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/shape/8.png); */
  background-position: center center;
  background-size: cover;
  width: 148px;
  height: 130px;
  left: 20px;
  top: -60px;
  z-index: -1;
  background-color: transparent;
  border-radius: 0;
}

.why-choose-two .why-choose-img img {
  -webkit-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
}

.why-choose-two .shapes img {
  position: absolute;
}

.why-choose-two .shapes :nth-child(1) {
  top: 40px;
  left: 100px;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

.why-choose-two .shapes :nth-child(2) {
  top: 180px;
  left: 220px;
  -webkit-animation: translateX 5s infinite linear;
          animation: translateX 5s infinite linear;
}

.why-choose-two .shapes :nth-child(3) {
  top: 50px;
  left: 350px;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

.why-choose-two .shapes :nth-child(4) {
  right: 350px;
  top: 35px;
  -webkit-animation: translateX 5s infinite linear;
          animation: translateX 5s infinite linear;
}

.why-choose-two .shapes :nth-child(5) {
  top: 250px;
  right: 250px;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

.why-choose-two .shapes :nth-child(6) {
  top: 50px;
  right: 110px;
  -webkit-animation: translateX 5s infinite linear;
          animation: translateX 5s infinite linear;
}

.why-choose-two .shapes :nth-child(7) {
  top: 295px;
  left: 500px;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

/* Why Choose CSS End */
/* Standard Section CSS Start */
.standard-section {
  padding-bottom: 90px;
  position: relative;
}

.standard-section .standard-text .section-title {
  margin: 0 0 30px;
}

.standard-section .standard-text p {
  margin-bottom: 15px;
}

.standard-section .standard-text .standard-btn {
  margin-top: 30px;
}

.standard-section .standard-text .standard-btn a {
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  padding: 10px 30px;
  color: #ffffff;
  border-radius: 30px;
  position: relative;
  -webkit-box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
          box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
  z-index: 0;
}

.standard-section .standard-text .standard-btn a::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: .5s;
  transition: .5s;
  background: -webkit-gradient(linear, left bottom, left top, from(#00b7ee), to(#0898e7));
  background: linear-gradient(0deg, #00b7ee 0%, #0898e7 100%);
}

.standard-section .standard-text .standard-btn a:hover::before {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.standard-section .standard-text .standard-btn a:hover {
  color: #36b0f1;
}

.standard-section .standard-img {
  position: relative;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

.standard-section .standard-img::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/shape/8.png); */
  background-position: center center;
  background-size: cover;
  width: 150px;
  height: 130px;
  right: 0;
  top: -50px;
  z-index: -1;
  -webkit-animation: translateX 3s infinite linear;
          animation: translateX 3s infinite linear;
}

.standard-section .standard-shape {
  position: absolute;
  bottom: 130px;
  right: 0;
  max-width: 500px;
  z-index: -1;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

/* Standard Section CSS End */
/* Our Apps Section CSS Start */
.our-apps {
  position: relative;
}

.our-apps .our-apps-img {
  position: relative;
  z-index: 0;
}

.our-apps .our-apps-img::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/shape/pattern-two.png); */
  background-size: cover;
  background-position: center center;
  width: 385px;
  height: 300px;
  bottom: 0;
  left: -84px;
  z-index: -1;
}

.our-apps .our-apps-img img {
  border-radius: 30px;
}

.our-apps .our-apps-text .section-title {
  margin: 0 0 30px;
}

.our-apps .our-apps-text p {
  margin-bottom: 40px;
}

.our-apps .our-apps-text .our-apps-btn {
  margin-bottom: 30px;
}

.our-apps .our-apps-text .our-apps-btn a {
  color: #0f2137;
  padding: 0 30px 20px;
  line-height: 1;
  border-radius: 50px;
  margin-right: 25px;
  position: relative;
  z-index: 0;
  -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15) !important;
  overflow: hidden;
}

.our-apps .our-apps-text .our-apps-btn a::before {
  position: absolute;
  content: '';
  background: -webkit-gradient(linear, left bottom, left top, from(#00b7eb), to(#0898e7));
  background: linear-gradient(0deg, #00b7eb 0%, #0898e7 100%);
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 50px;
  z-index: -1;
  -webkit-transition: .5s;
  transition: .5s;
}

.our-apps .our-apps-text .our-apps-btn a i {
  top: 23px;
  position: relative;
  left: -5px;
  font-size: 25px;
  -webkit-transition: .5s;
  transition: .5s;
}

.our-apps .our-apps-text .our-apps-btn a p {
  margin: 0 0 10px;
  padding-left: 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 0;
  -webkit-transition: .5s;
  transition: .5s;
}

.our-apps .our-apps-text .our-apps-btn a h5 {
  margin: 0;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  -webkit-transition: .5s;
  transition: .5s;
}

.our-apps .our-apps-text .our-apps-btn a:hover::before {
  width: 100%;
}

.our-apps .our-apps-text .our-apps-btn a:hover h5, .our-apps .our-apps-text .our-apps-btn a:hover i, .our-apps .our-apps-text .our-apps-btn a:hover p {
  color: #ffffff;
}

.our-apps .our-apps-text .our-apps-btn .active::before {
  width: 100%;
}

.our-apps .our-apps-text .our-apps-btn .active:hover::before {
  width: 0;
}

.our-apps .our-apps-text .our-apps-btn .active:hover h5, .our-apps .our-apps-text .our-apps-btn .active:hover i, .our-apps .our-apps-text .our-apps-btn .active:hover p {
  color: #0f2137;
}

.our-apps .our-apps-text .our-apps-btn .active h5, .our-apps .our-apps-text .our-apps-btn .active i, .our-apps .our-apps-text .our-apps-btn .active p {
  color: #ffffff;
}

.our-apps .apps-shape img {
  position: absolute;
  -webkit-animation: translateY 5s infinite linear;
          animation: translateY 5s infinite linear;
}

.our-apps .apps-shape :nth-child(1) {
  top: 0;
  left: 40px;
}

.our-apps .apps-shape :nth-child(2) {
  top: -80px;
  left: 600px;
}

.our-apps .apps-shape :nth-child(3) {
  top: -80px;
  right: 230px;
}

.our-apps .apps-shape :nth-child(4) {
  bottom: 60px;
  right: 100px;
}

.our-apps .apps-shape :nth-child(5) {
  top: 0;
  right: 0;
  max-width: 200px;
}

/* Our Apps Section CSS End */
/* Counter Section CSS Start */
.counter-section {
  position: relative;
  z-index: 1;
}

.counter-section::before {
  position: absolute;
  content: '';
  background: -webkit-gradient(linear, left bottom, left top, from(#00b7ee), to(#0899e7));
  background: linear-gradient(0deg, #00b7ee 0%, #0899e7 100%);
  width: 100%;
  height: 370px;
  left: 0;
  top: 0;
  z-index: -1;
}

.counter-section .counter-text {
  text-align: center;
  margin-bottom: 30px;
}

.counter-section .counter-text i {
  font-size: 40px;
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50px;
  display: inline-block;
  line-height: 80px;
  margin-bottom: 20px;
}

.counter-section .counter-text i.flaticon-value {
  color: #d2496a;
}

.counter-section .counter-text i.flaticon-customer-review {
  color: #9557f8;
}

.counter-section .counter-text i.flaticon-clipboard {
  color: #f343b0;
}

.counter-section .counter-text i.flaticon-start-up {
  color: #03abeb;
}

.counter-section .counter-text h2 {
  font-size: 35px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
}

.counter-section .counter-text p {
  color: #ffffff;
  margin-bottom: 0;
}

.counter-section .counter-img img {
  position: absolute;
}

.counter-section .counter-img :nth-child(1) {
  right: 50px;
  bottom: 0;
  max-width: 500px;
}

.counter-section .counter-img :nth-child(2) {
  right: 95px;
  bottom: 0;
  max-width: 125px;
}

/* Counter Section CSS End */
/* Footer Section CSS Start */
.footer-style-two {
  border-radius: 0 150px 0 0;
}

.footer-style-two .footer-widget .footer-logo {
  margin-bottom: 10px;
}

.footer-style-two .footer-widget p {
  margin-bottom: 26px;
}

.footer-style-two .footer-widget .footer-social a {
  margin-right: 6px;
}

.footer-style-two .footer-widget .footer-social a i {
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #28baef;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  -webkit-transition: .5s;
  transition: .5s;
}

.footer-style-two .footer-widget .footer-social a i:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  color: #1d6782;
}

/* Footer Section CSS End */
/*----- Sass Landing Page One End -----*/
/*----- Sass Landing Page Two Start -----*/
/* Banner Section Start */
.banner-bg-three {
  /* background-image: url(../img/sass-landing/banner-three.png); */
}

.main-banner-two .banner-image-three {
  position: absolute;
  bottom: 0;
  right: 30px;
  max-width: 580px;
  z-index: 99;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.main-banner-two .banner-image-three img {
  border-radius: 30px;
  -webkit-box-shadow: 0 6px 80px rgba(0, 0, 0, 0.25) !important;
          box-shadow: 0 6px 80px rgba(0, 0, 0, 0.25) !important;
}

.main-banner-two .banner-image-three :nth-child(2) {
  right: -25px;
  top: -34px;
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 !important;
          box-shadow: 0 0 0 0 !important;
  z-index: -1;
  border-radius: 0;
}

.main-banner-two .banner-shape-two img {
  position: absolute;
}

.main-banner-two .banner-shape-two :nth-child(1) {
  top: 125px;
  right: 250px;
  max-width: 300px;
  -webkit-animation: translateX 5s infinite linear;
          animation: translateX 5s infinite linear;
}

.main-banner-two .banner-shape-two :nth-child(2) {
  top: 125px;
  right: 350px;
  max-width: 300px;
  -webkit-animation: translateX 5s infinite linear;
          animation: translateX 5s infinite linear;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

/* Banner Section End */
/* Service Section Start */
.service-style-two {
  position: relative;
}

.service-style-two .section-title {
  max-width: 600px;
}

.service-style-two .service-item {
  border: 1px solid #ece7ff;
  border-radius: 20px 0 20px 20px;
  padding: 35px 24px;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: .5s;
  transition: .5s;
  background: #ffffff;
}

.service-style-two .service-item::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/sass-landing/shapes/2.png); */
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 200px;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  -webkit-transition: .5s;
  transition: .5s;
  border-radius: 20px 0 0 0;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.service-style-two .service-item:hover::before {
  opacity: 1;
}

.service-style-two .service-item:hover {
  -webkit-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15) !important;
}

.service-style-two .service-item img {
  margin-bottom: 20px;
}

.service-style-two .service-item h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.service-style-two .service-item p {
  margin-bottom: 0;
}

.service-style-two .service-shape img {
  position: absolute;
}

.service-style-two .service-shape :nth-child(1) {
  top: 0;
  left: 0;
  max-width: 250px;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.service-style-two .service-shape :nth-child(2) {
  top: 60px;
  right: 0;
  max-width: 115px;
  z-index: -1;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

/* Service Section End */
/* Feature Section Start */
.features-style-two {
  position: relative;
  padding-bottom: 100px;
}

.features-style-two .feature-text .section-title {
  margin: 0 0 25px 0;
}

.features-style-two .feature-text ul {
  padding-left: 0;
  margin-bottom: 35px;
}

.features-style-two .feature-text ul li {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  color: #0f2137;
  font-weight: 600;
  margin-bottom: 10px;
}

.features-style-two .feature-text ul li i {
  color: #7c58fc;
  font-size: 14px;
  margin-right: 5px;
}

.features-style-two .feature-text .feature-btn a {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background: #7c58fc;
  line-height: 1;
  padding: 20px 40px;
  border-radius: 30px;
}

.features-style-two .feature-text .feature-btn a:hover {
  background: #ffffff;
  color: #7c58fc;
  -webkit-box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
          box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
}

.features-style-two .featues-shape img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 570px;
  z-index: -1;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

/* Feature Section End */
/* Keep Update Section Start */
.keep-update {
  padding-bottom: 60px;
  position: relative;
}

.keep-update .section-title {
  max-width: 620px;
  margin: 0 0 25px;
}

.keep-update .section-title h2 {
  margin-bottom: 0;
}

.keep-update .keep-updating-text {
  margin-bottom: 30px;
}

.keep-update .keep-updating-text p {
  margin-bottom: 35px;
}

.keep-update .keep-updating-text .keep-btn {
  margin-bottom: 30px;
}

.keep-update .keep-updating-text .keep-btn a {
  color: #0f2137;
  padding: 0 30px 20px;
  line-height: 1;
  border-radius: 50px;
  margin-right: 25px;
  position: relative;
  z-index: 0;
  -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15) !important;
  overflow: hidden;
}

.keep-update .keep-updating-text .keep-btn a::before {
  position: absolute;
  content: '';
  background: -webkit-gradient(linear, left bottom, left top, from(#00b7eb), to(#0898e7));
  background: linear-gradient(0deg, #00b7eb 0%, #0898e7 100%);
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 50px;
  z-index: -1;
  -webkit-transition: .5s;
  transition: .5s;
}

.keep-update .keep-updating-text .keep-btn a i {
  top: 25px;
  position: relative;
  left: -5px;
  font-size: 25px;
  -webkit-transition: .5s;
  transition: .5s;
}

.keep-update .keep-updating-text .keep-btn a p {
  margin: 0 0 10px;
  padding-left: 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 0;
  -webkit-transition: .5s;
  transition: .5s;
}

.keep-update .keep-updating-text .keep-btn a h5 {
  margin: 0;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  -webkit-transition: .5s;
  transition: .5s;
}

.keep-update .keep-updating-text .keep-btn a:hover::before {
  width: 100%;
}

.keep-update .keep-updating-text .keep-btn a:hover h5, .keep-update .keep-updating-text .keep-btn a:hover i, .keep-update .keep-updating-text .keep-btn a:hover p {
  color: #ffffff;
}

.keep-update .keep-updating-text .keep-btn .active::before {
  width: 100%;
}

.keep-update .keep-updating-text .keep-btn .active:hover::before {
  width: 0;
}

.keep-update .keep-updating-text .keep-btn .active:hover h5, .keep-update .keep-updating-text .keep-btn .active:hover i, .keep-update .keep-updating-text .keep-btn .active:hover p {
  color: #0f2137;
}

.keep-update .keep-updating-text .keep-btn .active h5, .keep-update .keep-updating-text .keep-btn .active i, .keep-update .keep-updating-text .keep-btn .active p {
  color: #ffffff;
}

.keep-update .keep-img {
  position: relative;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.keep-update .keep-img::before {
  position: absolute;
  content: '';
  background: #c4b6fc;
  width: 200px;
  height: 200px;
  bottom: 20px;
  left: -20px;
  border-radius: 30px;
  z-index: -1;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.keep-update .keep-shape img {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 180px;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

/* Keep Update Section End */
/* Price Section Start */
.price-style-two {
  position: relative;
  padding-top: 200px;
  padding-bottom: 220px;
}

.price-style-two::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/sass-landing/price-bg.png); */
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.price-style-two .section-title {
  max-width: 650px;
}

.price-style-two .section-title h2, .price-style-two .section-title p {
  color: #ffffff;
}

.price-style-two .price-card {
  padding: 30px;
  border: 0;
  position: relative;
  z-index: 0;
  background: #ffffff;
}

.price-style-two .price-card::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/sass-landing/shapes/6.png); */
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 155px;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 25px 25px 0 0;
  -webkit-transition: .5s;
  transition: .5s;
}

.price-style-two .price-card::after {
  position: absolute;
  content: '';
  /* background-image: url(../img/sass-landing/shapes/7.png); */
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 155px;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 28px 28px 0 0;
  -webkit-transition: .5s;
  transition: .5s;
  opacity: 0;
}

.price-style-two .price-card:hover {
  background: #ffffff;
}

.price-style-two .price-card:hover::before {
  opacity: 0;
}

.price-style-two .price-card:hover::after {
  opacity: 1;
}

.price-style-two .price-card:hover p, .price-style-two .price-card:hover h3 {
  color: #ffffff;
}

.price-style-two .price-card:hover .price-feature ul li {
  color: #343d48;
}

.price-style-two .price-card:hover .price-feature ul li i {
  color: #32cd32;
}

.price-style-two .price-card:hover .price-btn a {
  background: #7c58fb;
  color: #ffffff;
}

.price-style-two .price-card .price {
  padding-bottom: 40px;
  z-index: 99;
  position: relative;
}

.price-style-two .price-card .price h3 {
  margin-bottom: 10px;
}

.price-style-two .price-card .price p {
  margin-bottom: 0;
}

.price-style-two .price-card .price-feature ul {
  margin-top: 50px;
  margin-bottom: 40px;
}

.price-style-two .active-price {
  background: #ffffff;
}

.price-style-two .active-price::before {
  opacity: 0;
}

.price-style-two .active-price::after {
  opacity: 1;
}

.price-style-two .active-price p, .price-style-two .active-price h3 {
  color: #ffffff;
}

.price-style-two .active-price .price-feature ul li {
  color: #343d48;
}

.price-style-two .active-price .price-feature ul li i {
  color: #32cd32;
}

.price-style-two .active-price .price-btn a {
  background: #7c58fb;
  color: #ffffff;
}

.price-style-two .price-shape img {
  position: absolute;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.price-style-two .price-shape :nth-child(1) {
  top: 50px;
  left: 0;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.price-style-two .price-shape :nth-child(2) {
  bottom: 0;
  left: 0;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.price-style-two .price-shape :nth-child(3) {
  bottom: 45px;
  right: 0;
  max-width: 200px;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.price-style-two .price-shape :nth-child(4) {
  top: 0;
  right: 0;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

/* Price Section End */
/* Counter Section Start */
.counter-style-two {
  position: relative;
}

.counter-style-two::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/sass-landing/counter-shape.png); */
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.counter-style-two .counter-text {
  text-align: center;
  margin-bottom: 30px;
  padding: 30px;
}

.counter-style-two .counter-text i {
  font-size: 50px;
}

.counter-style-two .counter-text i.flaticon-value {
  color: #cf496d;
}

.counter-style-two .counter-text i.flaticon-customer-review {
  color: #8d52eb;
}

.counter-style-two .counter-text i.flaticon-clipboard {
  color: #f84ca0;
}

.counter-style-two .counter-text i.flaticon-start-up {
  color: #05a2e9;
}

.counter-style-two .counter-text h3 {
  font-size: 35px;
  font-weight: 700;
  color: #282828;
  margin-bottom: 0;
}

.counter-style-two .counter-text p {
  font-size: 20px;
  color: #282828;
  margin-bottom: 0;
}

/* Counter Section End */
/* Testimonial Section Start */
.testimonial-style-two {
  padding-top: 30px;
  padding-bottom: 100px;
}

.testimonial-style-two .testimonial-slider-area {
  position: relative;
}

.testimonial-style-two .testimonial-slider-area::before {
  position: absolute;
  content: '';
  background: #f6f3ff;
  width: 115%;
  height: 130%;
  -webkit-clip-path: polygon(3% 39%, 99% 11%, 95% 75%, 6% 99%);
          clip-path: polygon(3% 39%, 99% 11%, 95% 75%, 6% 99%);
  top: -35px;
  left: -45px;
}

.testimonial-style-two .testimonial-slider-area .owl-prev, .testimonial-style-two .testimonial-slider-area .owl-next {
  width: 45px;
  height: 45px;
  border: 1px solid #7c58fc !important;
  display: inline-block;
  position: absolute;
  left: -110px;
  line-height: 45px !important;
  font-size: 16px !important;
  color: #7c58fc !important;
  border-radius: 50px;
  top: 50%;
  -webkit-transition: .5s;
  transition: .5s;
}

.testimonial-style-two .testimonial-slider-area .owl-prev:hover, .testimonial-style-two .testimonial-slider-area .owl-next:hover {
  background: #7c58fc;
  color: #ffffff !important;
}

.testimonial-style-two .testimonial-slider-area .owl-next {
  left: inherit;
  right: -110px;
}

.testimonial-style-two .testimonial-slider-area .testimonial-items {
  background: #7c58fc;
  -webkit-clip-path: polygon(0 0%, 100% 19%, 97% 100%, 5% 100%);
          clip-path: polygon(0 0%, 100% 19%, 97% 100%, 5% 100%);
  padding: 60px;
}

.testimonial-style-two .testimonial-slider-area .testimonial-items .client-info {
  float: left;
  padding-left: 50px;
}

.testimonial-style-two .testimonial-slider-area .testimonial-items .client-info img {
  max-width: 80px;
  margin-bottom: 15px;
}

.testimonial-style-two .testimonial-slider-area .testimonial-items .client-info h6 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #ffffff;
}

.testimonial-style-two .testimonial-slider-area .testimonial-items .client-info p {
  color: #ffffff;
  margin-bottom: 0;
}

.testimonial-style-two .testimonial-slider-area .testimonial-items .client-text {
  margin-bottom: 0;
  padding: 50px 0px 0px 170px;
  text-align: center;
  color: #ffffff;
}

/* Testimonial Section End */
/* Newsletter Section Start */
.newsletter-style-two {
  background: #293767;
  position: relative;
}

.newsletter-style-two::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/sass-landing/counter-shape.png); */
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.newsletter-style-two .newsletter-area {
  border-radius: 0;
  background: none;
  padding: 0;
}

.newsletter-style-two .newsletter-area .form-control {
  background: #ffffff !important;
  color: #293767 !important;
}

.newsletter-style-two .newsletter-area ::-webkit-input-placeholder {
  color: #293767 !important;
  font-weight: 600;
}

.newsletter-style-two .newsletter-area :-ms-input-placeholder {
  color: #293767 !important;
  font-weight: 600;
}

.newsletter-style-two .newsletter-area ::-ms-input-placeholder {
  color: #293767 !important;
  font-weight: 600;
}

.newsletter-style-two .newsletter-area ::placeholder {
  color: #293767 !important;
  font-weight: 600;
}

.newsletter-style-two .newsletter-area .default-btn {
  background: #7c58fc !important;
  color: #ffffff !important;
}

/* Newsletter Section End */
/* Footer Area Start */
.footer-style-three {
  background: #ffffff;
}

.footer-style-three .footer-logo {
  margin-bottom: 5px;
}

.footer-style-three .footer-widget p {
  color: #717477;
}

.footer-style-three .footer-widget h3 {
  color: #717477;
}

.footer-style-three .footer-widget ul li {
  color: #717477;
}

.footer-style-three .footer-widget ul li a {
  color: #717477;
}

.footer-style-three .footer-widget .footer-social a i {
  background: #7c58fc;
}

.footer-style-three .footer-widget .footer-social a i:hover {
  color: #0f2137;
}

.footer-style-three .copyright-area p {
  color: #717477;
}

.footer-style-three .copyright-area p a {
  color: #717477;
}

/* Footer Area End */
/*----- Sass Landing Page Two End -----*/
/*----- Personal Portfolio Page Start -----*/
/* Banner CSS Start */
.banner-style-four {
  background: #1e2838;
  height: 100vh;
  padding-top: 0;
}

.banner-style-four .banner-text {
  padding-top: 60px;
}

.banner-style-four .banner-text span {
  color: #ffffff;
  font-size: 24px;
}

.banner-style-four .banner-text h1 {
  margin-bottom: 15px;
  font-size: 50px;
}

.banner-style-four .banner-text h6,h3.Homepage-banner-title {
  color: #ffffff;
  margin-bottom: 30px;
}

.banner-style-four .banner-text p {
  margin-bottom: 35px;
}

.banner-style-four .banner-text .banner-btn a {
  margin-right: 20px;
}

.banner-style-four .banner-text .banner-btn .cv-btn {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  -webkit-box-shadow: 0 0 0 0 !important;
          box-shadow: 0 0 0 0 !important;
}

.banner-style-four .banner-text .banner-btn .cv-btn:hover {
  background-color: #ffffff;
  color: #03abeb;
  -webkit-box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
          box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
}

.banner-style-four .banner-img-two {
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
  padding-top: 60px;
}

/* Banner CSS Start */
/* About CSS Start */
.about-section {
  position: relative;
}

.about-section .about-image {
  /* background-image: url(../img/personal-portfolio/2.png); */
  background-position: center center;
  background-size: cover;
}

.about-section .about-img {
  position: relative;
}

.about-section .about-img .about-img-text {
  position: absolute;
  bottom: 0;
  right: 0;
}

.about-section .about-img .about-img-text p {
  color: #3454d1;
  background: #ffffff;
  line-height: 1;
  padding: 24px 0 24px 45px;
  border-radius: 10px 0 0 10px;
  position: relative;
}

.about-section .about-img .about-img-text p::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-top: 20px solid #e9e9e9;
  border-right: 20px solid transparent;
  bottom: -20px;
  right: -20px;
}

.about-section .about-img .about-img-text ul {
  padding-left: 0;
}

.about-section .about-img .about-img-text ul li {
  display: inline-block;
}

.about-section .about-img .about-img-text ul li a {
  color: #434342;
}

.about-section .about-img .about-img-text ul li a i {
  width: 45px;
  height: 45px;
  border: 1px solid #434342;
  display: inline-block;
  text-align: center;
  line-height: 45px;
  border-radius: 30px;
  margin-right: 10px;
  font-size: 18px;
  -webkit-transition: .5s;
  transition: .5s;
}

.about-section .about-img .about-img-text ul li a i:hover {
  background: #ea4c89;
  color: #ffffff;
}

.about-section .about-text {
  padding-left: 55px;
  padding-right: 40px;
  margin-bottom: 30px;
}

.about-section .about-text h2 {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 25px;
}

.about-section .about-text p {
  margin-bottom: 30px;
}

.about-section .about-text ul {
  padding-left: 0;
  margin-bottom: 20px;
}

.about-section .about-text ul li {
  display: inline-block;
  height: 60px;
  background: #eef1ff;
  border-radius: 30px;
  width: 300px;
  padding: 5px 0 0 10px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.about-section .about-text ul li i {
  background: #3454d1;
  color: #ffffff;
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  position: relative;
  top: 0;
  border-radius: 50px;
  left: -5px;
  text-align: center;
  font-size: 20px;
}

.about-section .about-text .about-btn a {
  color: #3454d1;
  background: #ffffff;
  border: 1px solid #3454d1;
  border-radius: 30px;
  margin-right: 15px;
  padding: 10px 50px;
  font-weight: 600;
}

.about-section .about-text .about-btn a:hover {
  background: #3454d1;
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.about-section .about-shape img {
  position: absolute;
  top: 130px;
  right: 0;
  max-width: 250px;
}

/* About CSS End */
/* Service CSS Start */
.service-style-three .service-items {
  text-align: center;
  padding: 30px 25px;
  border-radius: 0 0 20px 20px;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  -webkit-transition: .5s;
  transition: .5s;
}

.service-style-three .service-items:hover {
  background: #874fe1;
}

.service-style-three .service-items:hover i, .service-style-three .service-items:hover h3, .service-style-three .service-items:hover p {
  color: #ffffff !important;
}

.service-style-three .service-items:hover i {
  background: #9869e4;
}

.service-style-three .service-items i {
  font-size: 40px;
  margin-top: -30px;
  margin-bottom: 30px;
  display: inline-block;
  background: #e5eaff;
  border-radius: 0 0 15px 15px;
  -webkit-transition: .5s;
  transition: .5s;
  line-height: 1;
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.service-style-three .service-items i.flaticon-ui {
  color: #4e73ff;
}

.service-style-three .service-items i.flaticon-web-programming {
  color: #06b781;
}

.service-style-three .service-items i.flaticon-app-1 {
  color: #3454d1;
}

.service-style-three .service-items i.flaticon-content-writing {
  color: #fe8700;
}

.service-style-three .service-items i.flaticon-java {
  color: #ffffff;
}

.service-style-three .service-items i.flaticon-online-video {
  color: #e44f4f;
}

.service-style-three .service-items h3 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 12px;
  -webkit-transition: .5s;
  transition: .5s;
}

.service-style-three .service-items p {
  margin-bottom: 0;
  -webkit-transition: .5s;
  transition: .5s;
}

.service-style-three .service-items.active {
  background: #874fe1;
}

.service-style-three .service-items.active i, .service-style-three .service-items.active h3, .service-style-three .service-items.active p {
  color: #ffffff !important;
}

.service-style-three .service-items.active i {
  background: #9869e4;
}

/* Service CSS End */
/* Software CSS Start */
.software-section {
  background: #3454d1;
  position: relative;
  z-index: 1;
}

.software-section::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/personal-portfolio/map.png); */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.software-section .section-title h2 {
  color: #ffffff;
}

.software-section .section-title p {
  color: #ffffff;
}

.software-section .software-img {
  position: relative;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 30px;
}

.software-section .software-img img {
  width: 100%;
}

.software-section .software-img .software-img-text {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  padding: 25px 20px;
  border-radius: 0 0 10px 10px;
}

.software-section .software-img .software-img-text img {
  max-width: 40px;
}

.software-section .software-img .software-img-text p {
  margin-bottom: 0;
  position: absolute;
  top: 30px;
  left: 75px;
  font-size: 18px;
  font-weight: 700;
  color: #3156d2;
}

.software-section .software-text {
  padding-left: 20px;
}

.software-section .software-text .nav-tabs {
  border-bottom: 0;
  margin-bottom: 30px;
}

.software-section .software-text .nav-tabs li {
  padding: 0;
  background: none;
}

.software-section .software-text .nav-tabs li a {
  color: #ffffff;
  border: 0;
  border-bottom: 3px solid transparent;
  font-weight: 600;
  padding: 0;
}

.software-section .software-text .nav-tabs li a.active {
  border-bottom: 3px solid #ffffff !important;
  color: #ffffff !important;
}

.software-section .software-text h2 {
  color: #ffffff;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 20px;
}

.software-section .software-text p {
  color: #ffffff;
  margin-bottom: 15px;
}

.software-section .software-text h3 {
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
}

.software-section .software-text ul {
  padding-left: 0;
  margin-bottom: 40px;
}

.software-section .software-text ul li {
  background: #84abeb;
  padding: 15px;
  display: inline-block;
  margin-right: 20px;
  border-radius: 10px;
}

.software-section .software-text ul li img {
  width: 30px;
  height: 30px;
}

.software-section .software-text .software-link {
  color: #3454d1;
  background: #ffffff;
  font-weight: 600;
  border-radius: 30px;
  line-height: 1;
  padding: 15px 50px;
  border: 1px solid transparent;
}

.software-section .software-text .software-link:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
  background: transparent;
}

.software-section .software-shape img {
  position: absolute;
}

.software-section .software-shape :nth-child(1) {
  bottom: 0;
  left: 0;
  width: 150px;
  -webkit-transform: rotate(65deg);
          transform: rotate(65deg);
}

.software-section .software-shape :nth-child(2) {
  bottom: 50px;
  right: 100px;
}

.software-section .software-shape :nth-child(3) {
  bottom: 165px;
  left: 0;
  -webkit-transform: rotate(65deg);
          transform: rotate(65deg);
  width: 90px;
}

.software-section .software-shape :nth-child(4) {
  top: 0;
  right: 0;
  width: 100px;
}

/* Software CSS End */
/* Showcase CSS Start */
.show-case-section {
  position: relative;
  background: #eff7ff;
}

.show-case-section::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/personal-portfolio/showcase-shape.png); */
  background-position: center center;
  background-size: cover;
  width: 250px;
  height: 250px;
  top: 0;
  right: 0;
}

.show-case-section .showcase-menu ul {
  padding-left: 0;
  margin-bottom: 60px;
}

.show-case-section .showcase-menu ul li {
  font-size: 16px;
  display: inline-block;
  padding: 10px 30px;
  border: 1px solid #acbdff;
  border-radius: 30px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-transition: .5s;
  transition: .5s;
}

.show-case-section .showcase-menu ul li:hover {
  background: #255cd6;
  color: #ffffff;
}

.show-case-section .showcase-menu .mixitup-control-active {
  background: #255cd6;
  color: #ffffff;
}

.show-case-section .showcase-card {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.show-case-section .showcase-card:hover .shoscase-hover {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.show-case-section .showcase-card img {
  width: 100%;
}

.show-case-section .showcase-card .showcsae-text {
  padding: 20px;
}

.show-case-section .showcase-card .showcsae-text h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.show-case-section .showcase-card .showcsae-text p {
  margin-bottom: 0;
}

.show-case-section .showcase-card .shoscase-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(52, 84, 209, 0.95);
  top: 0;
  left: 0;
  border-radius: 15px;
  padding: 0 20px;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: 1s;
  transition: 1s;
}

.show-case-section .showcase-card .shoscase-hover h3 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}

.show-case-section .showcase-card .shoscase-hover p {
  color: #ffffff;
  font-size: 15px;
  margin-bottom: 25px;
}

.show-case-section .showcase-card .shoscase-hover ul {
  padding-left: 0;
}

.show-case-section .showcase-card .shoscase-hover ul li {
  display: inline-block;
}

.show-case-section .showcase-card .shoscase-hover ul li a {
  color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  border: 1px solid;
  line-height: 37px;
  margin: 0 5px;
}

.show-case-section .showcase-card .shoscase-hover ul li a:hover {
  background: #ffffff;
  color: #3454d1;
}

/* Showcase CSS End */
/* Testimonial CSS Start */
.testimonial-style-three {
  padding-bottom: 100px;
}

.testimonial-style-three .testimonial-area {
  overflow: hidden;
  position: relative;
  padding-bottom: 20px;
}

.testimonial-style-three .testimonial-area::after {
  position: absolute;
  content: "";
  left: 0;
  top: 23%;
  right: 0;
  width: 50%;
  height: 75%;
  margin: auto;
  border-radius: 30px;
  background: #3056d2;
}

.testimonial-style-three .testimonial-area::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/personal-portfolio/testimonial-shape.png); */
  background-position: center center;
  background-size: cover;
  width: 150px;
  height: 150px;
  top: 23%;
  left: 25%;
  z-index: 1;
}

.testimonial-style-three .testimonial-image-slider {
  margin-top: 5px;
}

.testimonial-style-three .slick-list {
  margin: 0 5px;
}

.testimonial-style-three .sin-testiImage {
  display: inline-block;
  margin-bottom: 11px;
  outline: medium none;
}

.testimonial-style-three .sin-testiImage img {
  border: 3px solid #4e73ff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block !important;
  height: 70px;
  margin-bottom: 20px;
  margin-top: 36px;
  outline: 0 none;
  width: 70px;
}

.testimonial-style-three .sin-testiImage.slick-current img {
  border: 5px solid #4e73ff;
  height: 115px;
  margin-top: 0;
  opacity: 1;
  width: 115px;
  -webkit-box-shadow: 0px 16px 16px 0 #00000061;
          box-shadow: 0px 16px 16px 0 #00000061;
}

.testimonial-style-three .testimonial-text-slider {
  margin: auto;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 9;
}

.testimonial-style-three .testimonial-text-slider h2 {
  color: #ffffff;
  display: block;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.testimonial-style-three .sin-testiText {
  outline: none;
}

.testimonial-style-three .sin-testiText span {
  color: #ffffff;
}

.testimonial-style-three .sin-testiText p {
  margin-top: 22px;
  color: #ffffff;
  line-height: 24px;
}

.testimonial-style-three .slick-prev, .testimonial-style-three .slick-next {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 25px;
  outline: medium none;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 16px;
}

.testimonial-style-three .slick-prev {
  left: 0px;
}

.testimonial-style-three .slick-next {
  right: 0px;
}

.testimonial-style-three .slick-prev::before, .testimonial-style-three .slick-next::before {
  height: 40px;
  width: 40px;
  line-height: 37px;
  left: -190px;
  top: 40px;
  border-radius: 30px;
  position: absolute;
  color: #225ed7;
  content: "\f10e";
  font-family: "Flaticon";
  font-size: 50px !important;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  border: 1px solid #225ed7;
  font-size: 16px !important;
  opacity: 0;
}

.testimonial-style-three .slick-next:before {
  left: inherit;
  right: -190px;
  content: "\f10f";
}

.testimonial-style-three .slick-slider {
  z-index: 9;
}

.testimonial-style-three .testimonial-area:hover .slick-prev::before {
  opacity: 1;
  left: -150px;
}

.testimonial-style-three .testimonial-area:hover .slick-next::before {
  opacity: 1;
  right: -150px;
}

/* Testimonial CSS End */
/* Contact CSS Start */
.contact-bg {
  /* background-image: url(../img/personal-portfolio/contact-bg.png); */
  background-position: center center;
  background-size: cover;
}

.contact-section .contact-form {
  padding: 70px;
  margin-top: 100px;
  border-radius: 20px;
  z-index: 1;
  position: relative;
  margin-bottom: -200px;
  background: #ffffff;
  -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 35px rgba(0, 0, 0, 0.15);
}

.contact-section .contact-form h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 40px;
}

.contact-section .contact-form .form-group {
  margin-bottom: 20px;
}

.contact-section .contact-form .form-group .form-control {
  height: 60px;
  border: 1px solid #dde4ff;
  border-radius: 30px;
  padding: 0 20px;
}

.contact-section .contact-form .form-group .form-control:focus {
  border: 1px solid #4e73ff;
}

.contact-section .contact-form .form-group .form-control ::-webkit-input-placeholder {
  color: #343d48;
  opacity: .5;
}

.contact-section .contact-form .form-group .form-control :-ms-input-placeholder {
  color: #343d48;
  opacity: .5;
}

.contact-section .contact-form .form-group .form-control ::-ms-input-placeholder {
  color: #343d48;
  opacity: .5;
}

.contact-section .contact-form .form-group .form-control ::placeholder {
  color: #343d48;
  opacity: .5;
}

.contact-section .contact-form .message-field {
  border: 1px solid #dde4ff;
  border-radius: 30px;
  padding: 0 20px;
  width: 100%;
  padding-top: 15px;
  outline: 0;
}

.contact-section .contact-form .contact-btn {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  padding: 15px 40px;
  background: #3454d1;
  border-radius: 30px;
  margin-top: 20px;
  -webkit-transition: .5s;
  transition: .5s;
  border: 1px solid transparent;
}

.contact-section .contact-form .contact-btn:hover {
  background: #ffffff;
  border: 1px solid #3454d1;
  color: #3454d1;
}

.contact-section .contact-form .list-unstyled {
  color: #dc3545;
}

.contact-section .contact-form .h4 {
  font-size: 18px;
  margin-top: 20px;
}

/* Contact CSS End */
/* Footer CSS Start */
.footer-style-four {
  background: #3454d1;
  border-radius: 0;
  padding-bottom: 100px;
  padding-top: 300px;
  position: relative;
}

.footer-style-four::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/personal-portfolio/map.png); */
  background-position: center center;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer-style-four .footer-widget {
  padding-bottom: 0;
}

.footer-style-four .footer-widget .footer-logo {
  margin-bottom: 10px;
}

.footer-style-four .footer-widget p {
  color: #ffffff;
  margin-bottom: 23px;
}

.footer-style-four .footer-widget h3 {
  color: #ffffff;
}

.footer-style-four .footer-widget ul li {
  color: #ffffff;
  margin-bottom: 17px;
}

.footer-style-four .footer-widget ul li a {
  color: #ffffff;
}

.footer-style-four .footer-widget ul li a:hover {
  color: #000000;
}

.footer-style-four .footer-widget ul li a:hover i {
  color: #000000;
}

.footer-style-four .footer-widget ul li a i {
  -webkit-transition: .5s;
  transition: .5s;
}

.footer-style-four .footer-widget .footer-social {
  margin-top: 25px;
}

.footer-style-four .footer-widget .footer-social a i {
  color: #80b8f1;
  border: 1px solid #80b8f1;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 30px;
  font-size: 12px;
  background: transparent;
  -webkit-transition: .5s;
  transition: .5s;
  margin-right: 5px;
}

.footer-style-four .footer-widget .footer-social a i:hover {
  background: #ea4c89;
  color: #ffffff;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.footer-style-four .footer-widget .footer-btn a {
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 80px;
  margin-right: 8px;
  font-size: 14px;
}

.footer-style-four .footer-widget .footer-btn a:hover {
  background: #ffffff;
  color: #3454d1;
  -webkit-box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
          box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25) !important;
}

/* Footer CSS End */
/*----- Personal Portfolio Page End -----*/
/*----- Creative Agency Page One Start -----*/
.nav-style-two.is-sticky {
  background: -webkit-gradient(linear, left top, right top, color-stop(20%, #e4665c), to(#9b5ba3));
  background: linear-gradient(90deg, #e4665c 20%, #9b5ba3 100%);
}

/* Home CSS Start */
.banner-bg-four {
  /* background-image: url(../img/creative-agency/banner.png); */
  position: relative;
  height: 100vh;
}

.banner-bg-four::before {
  position: absolute;
  content: '';
  background: -webkit-gradient(linear, left top, right top, color-stop(20%, rgba(228, 102, 92, 0.85)), to(rgba(155, 91, 163, 0.85)));
  background: linear-gradient(90deg, rgba(228, 102, 92, 0.85) 20%, rgba(155, 91, 163, 0.85) 100%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.banner-style-three .banner-text {
  z-index: 1;
  position: relative;
  padding-top: 60px;
}

.banner-style-three .banner-text h1 {
  margin-bottom: 27px;
  text-transform: uppercase;
}

.banner-style-three .banner-text .banner-btn a {
  background: transparent;
  -webkit-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.banner-style-three .banner-text .banner-btn a:hover {
  background: #ffffff;
  color: #e4665c;
}

.banner-style-three .banner-text .banner-btn .active {
  background: #ffffff;
  color: #e4665c;
}

.banner-style-three .banner-img-three {
  position: absolute;
  right: 90px;
  bottom: 0;
  max-width: 600px;
  -webkit-animation: translateX 10s infinite linear;
          animation: translateX 10s infinite linear;
}

/* Home CSS End */
/* About CSS Start */
.about-us-section {
  position: relative;
}

.about-us-section .about-image {
  position: relative;
}

.about-us-section .about-image::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/creative-agency/about-shape.png); */
  background-position: center center;
  background-size: cover;
  width: 94%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: translateY 10s infinite linear;
          animation: translateY 10s infinite linear;
}

.about-us-section .about-image::after {
  position: absolute;
  content: '';
  /* background-image: url(../img/creative-agency/about-shape.png); */
  background-position: center center;
  background-size: cover;
  width: 70%;
  height: 80%;
  top: 0;
  left: 35px;
  z-index: -1;
  -webkit-transform: rotate(335deg);
          transform: rotate(335deg);
  -webkit-animation: translateY 20s infinite linear;
          animation: translateY 20s infinite linear;
}

.about-us-section .section-title {
  margin: 0 0 20px;
}

.about-us-section .about-text {
  padding-left: 50px;
  padding-right: 30px;
}

.about-us-section .about-text ul {
  padding-left: 0;
  margin-bottom: 40px;
}

.about-us-section .about-text ul li {
  font-size: 18px;
  color: #343d48;
  font-weight: 600;
  margin-bottom: 5px;
}

.about-us-section .about-text ul li i {
  color: #efb8ba;
  margin-right: 5px;
  font-size: 14px;
}

.about-us-section .about-text .about-btn a {
  color: #ffffff;
  padding: 10px 40px;
  font-weight: 600;
  border-radius: 30px;
  border: 1px solid transparent;
  background: -webkit-gradient(linear, left top, right top, from(#e67b72), to(#cf708d));
  background: linear-gradient(90deg, #e67b72 0%, #cf708d 100%);
}

.about-us-section .about-text .about-btn a:hover {
  background: none;
  border: 1px solid #f71c0a;
  color: #f71c0a;
}

.about-us-section .about-shape img {
  position: absolute;
  bottom: 60px;
  right: 0;
  width: 300px;
}

/* About CSS End */
/* Service CSS Start */
.service-style-four .service-card {
  text-align: center;
  padding: 35px;
  border-radius: 30px 0 30px 30px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
  position: relative;
  z-index: 0;
}

.service-style-four .service-card:hover::before {
  height: 100%;
}

.service-style-four .service-card::before {
  position: absolute;
  content: '';
  background: -webkit-gradient(linear, left top, right top, from(#e4665c), to(#d95978));
  background: linear-gradient(90deg, #e4665c 0%, #d95978 100%);
  width: 100%;
  height: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px 0 30px 30px;
  z-index: -1;
  -webkit-transition: .5s;
  transition: .5s;
}

.service-style-four .service-card:hover i, .service-style-four .service-card:hover h3, .service-style-four .service-card:hover p {
  color: #ffffff;
}

.service-style-four .service-card:hover i {
  background: #ce5f4d;
}

.service-style-four .service-card i {
  color: #d2587f;
  font-size: 35px;
  margin-bottom: 25px;
  display: inline-block;
  -webkit-transition: .5s;
  transition: .5s;
  width: 70px;
  height: 70px;
  background: #f6e6ef;
  border-radius: 50px;
  line-height: 70px;
}

.service-style-four .service-card h3 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
  -webkit-transition: .5s;
  transition: .5s;
  font-weight: 600;
}

.service-style-four .service-card p {
  margin-bottom: 0;
  -webkit-transition: .5s;
  transition: .5s;
}

/* Service CSS End */
/* Process CSS Start */
.work-process {
  background: #fbe8e7;
  border-radius: 150px 0 150px 0;
}

.work-process .process-card {
  background: -webkit-gradient(linear, left top, right top, from(#e4665c), to(#d95978));
  background: linear-gradient(90deg, #e4665c 0%, #d95978 100%);
  height: 160px;
  text-align: center;
  position: relative;
  padding: 10px;
  border-radius: 100px;
  margin-bottom: 30px;
}

.work-process .process-card::before {
  position: absolute;
  content: '';
  width: 90%;
  height: 90%;
  top: 8px;
  left: 0;
  border: 1px dashed #ffffff;
  right: 0;
  margin: auto;
  border-radius: 100px;
}

.work-process .process-card h2 {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 70px;
  font-weight: 700;
  color: #ffffff;
  opacity: .08;
}

.work-process .process-card h3 {
  padding-top: 50px;
  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
}

.work-process .card-two {
  background: -webkit-gradient(linear, left top, right top, from(#c05692), to(#9b5ba3));
  background: linear-gradient(90deg, #c05692 0%, #9b5ba3 100%);
}

.work-process .card-three {
  background: -webkit-gradient(linear, left top, right top, from(#6b60aa), to(#9b5ba3));
  background: linear-gradient(90deg, #6b60aa 0%, #9b5ba3 100%);
}

.work-process .card-four {
  background: -webkit-gradient(linear, left top, right top, from(#2b62a5), to(#9b5ba3));
  background: linear-gradient(90deg, #2b62a5 0%, #9b5ba3 100%);
}

.work-process .card-five {
  background: -webkit-gradient(linear, left top, right top, from(#d95978), to(#e4665c));
  background: linear-gradient(90deg, #d95978 0%, #e4665c 100%);
}

/* Process CSS End */
/* Case Study CSS Start */
.case-study-section {
  padding-bottom: 125px;
}

.case-study-section .case-study-slider {
  position: relative;
}

.case-study-section .case-study-slider .case-items {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 30px;
}

.case-study-section .case-study-slider .case-items:hover .case-img::before {
  opacity: 1;
}

.case-study-section .case-study-slider .case-items:hover .case-study-text h3 a {
  color: #e4665d;
}

.case-study-section .case-study-slider .case-items .case-img {
  position: relative;
}

.case-study-section .case-study-slider .case-items .case-img::before {
  position: absolute;
  content: '';
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: .5s;
  transition: .5s;
  border: 10px 10px 0 0;
}

.case-study-section .case-study-slider .case-items .case-img .project-logo {
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: -50px;
  left: 30px;
  background: #ffffff;
  border-radius: 120px;
  -webkit-box-shadow: 0 0 20px 0 #00000026;
          box-shadow: 0 0 20px 0 #00000026;
}

.case-study-section .case-study-slider .case-items .case-img .project-logo img {
  max-width: 50px;
  margin: auto;
}

.case-study-section .case-study-slider .case-items .case-study-text {
  padding: 70px 30px 30px;
}

.case-study-section .case-study-slider .case-items .case-study-text h3 a {
  font-size: 25px;
  color: #003041;
  font-weight: 600;
  margin-bottom: 10px;
}

.case-study-section .case-study-slider .owl-dots {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  margin: auto;
}

.case-study-section .case-study-slider .owl-dot span {
  color: #edbabe;
}

.case-study-section .case-study-slider .owl-dot.active span {
  color: #da7782;
}

/* Case Study CSS End */
/* Testimonial CSS Start */
.testimonial-style-four {
  padding-bottom: 200px;
}

.testimonial-style-four .section-title {
  margin-bottom: 130px;
}

.testimonial-style-four .testimonial-slider-wrapper {
  position: relative;
}

.testimonial-style-four .testimonial-slider-wrapper::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 180%;
  top: -90px;
  left: 0;
  z-index: -1;
  background: #003041;
  -webkit-clip-path: polygon(0 0, 100% 5%, 95% 79%, 6% 90%);
          clip-path: polygon(0 0, 100% 5%, 95% 79%, 6% 90%);
}

.testimonial-style-four .testimonial-slider-wrapper .tsetimonial-card {
  text-align: center;
  max-width: 500px;
  margin: auto;
}

.testimonial-style-four .testimonial-slider-wrapper .tsetimonial-card img {
  width: 100px;
  margin: 0 auto 25px;
}

.testimonial-style-four .testimonial-slider-wrapper .tsetimonial-card h3 {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}

.testimonial-style-four .testimonial-slider-wrapper .tsetimonial-card span {
  margin-bottom: 20px;
  color: #ffffff;
  display: inline-block;
}

.testimonial-style-four .testimonial-slider-wrapper .tsetimonial-card p {
  font-size: 18px;
  color: #ffffff;
}

.testimonial-style-four .testimonial-slider-wrapper .owl-prev, .testimonial-style-four .testimonial-slider-wrapper .owl-next {
  position: absolute;
  top: 40%;
  left: -70px;
  width: 45px;
  height: 45px;
  -webkit-transition: .5s;
  transition: .5s;
  display: inherit;
  border-radius: 30px;
  font-size: 30px !important;
  line-height: 40px !important;
  color: #ffffff !important;
  background: #efbcbe !important;
}

.testimonial-style-four .testimonial-slider-wrapper .owl-prev:hover, .testimonial-style-four .testimonial-slider-wrapper .owl-next:hover {
  background: #e47a79 !important;
}

.testimonial-style-four .testimonial-slider-wrapper .owl-next {
  left: inherit;
  right: -70px;
}

/* Testimonial CSS End */
/* Price CSS Start */
.price-style-three {
  background: -webkit-gradient(linear, left top, right top, from(#e4665c), to(#d95978));
  background: linear-gradient(90deg, #e4665c 0%, #d95978 100%);
  position: relative;
}

.price-style-three::before {
  position: absolute;
  content: '';
  /* background-image: url(../img/creative-agency/price-bg.png); */
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.price-style-three .section-title {
  max-width: 610px;
  margin-bottom: 60px;
}

.price-style-three .section-title span {
  color: #ffffff;
}

.price-style-three .section-title h2 {
  color: #ffffff;
  margin-bottom: 20px;
}

.price-style-three .section-title p {
  color: #ffffff;
}

.price-style-three .price-card {
  padding: 0 0 40px;
  border: 0;
  background: #ffffff;
}

.price-style-three .price-card:hover {
  background: #ffffff;
}

.price-style-three .price-card:hover .price-btn a {
  color: #ffffff;
}

.price-style-three .price-card:hover .price-btn a::before {
  opacity: 1;
}

.price-style-three .price-card:hover .price-feature ul li {
  color: #343d48;
}

.price-style-three .price-card:hover .price-feature ul li i {
  color: #32cd32;
}

.price-style-three .price-card .price-heading {
  background: #003041;
  border-radius: 30px 30px 40px 0px;
  padding: 40px;
}

.price-style-three .price-card .price-heading h3 {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 15px;
  line-height: 1;
}

.price-style-three .price-card .price-heading h2 {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0;
  line-height: 1;
}

.price-style-three .price-card .price-heading h2 span {
  font-size: 15px;
  font-weight: 400;
}

.price-style-three .price-card .price-feature {
  padding: 0 50px;
}

.price-style-three .price-card .price-feature ul {
  margin-top: 40px;
}

.price-style-three .price-card .price-btn a {
  position: relative;
  z-index: 0;
  color: #e4665c;
}

.price-style-three .price-card .price-btn a::before {
  position: absolute;
  content: '';
  background: -webkit-gradient(linear, left top, right top, from(#e4665c), to(#d95978));
  background: linear-gradient(90deg, #e4665c 0%, #d95978 100%);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
  -webkit-transition: .5s;
  transition: .5s;
}

.price-style-three .price-card.active .price-btn a::before {
  opacity: 1;
}

.price-style-three .price-card.active .price-btn a {
  color: #ffffff;
}

/* Price CSS End */
/* Team CSS Start */
.team-section .team-slider .owl-stage-outer {
  padding-top: 10px;
}

.team-section .team-slider .team-card {
  overflow: hidden;
  -webkit-transition: .8s;
  transition: .8s;
}

.team-section .team-slider .team-card:hover {
  margin-top: -10px;
  border-radius: 10px;
}

.team-section .team-slider .team-card:hover .team-img .team-hover {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  border-radius: 10px;
}

.team-section .team-slider .team-card .team-img {
  position: relative;
  border-radius: 10px;
}

.team-section .team-slider .team-card .team-img img {
  border-radius: 10px;
}

.team-section .team-slider .team-card .team-img .team-hover {
  position: absolute;
  background: rgba(225, 98, 101, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px !important;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: .8s;
  transition: .8s;
}

.team-section .team-slider .team-card .team-img .team-hover .team-hover-text {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.team-section .team-slider .team-card .team-img .team-hover .team-hover-text h3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
}

.team-section .team-slider .team-card .team-img .team-hover .team-hover-text p {
  color: #ffffff;
  margin-bottom: 10px;
}

.team-section .team-slider .team-card .team-img .team-hover .team-hover-text ul {
  padding-left: 0;
}

.team-section .team-slider .team-card .team-img .team-hover .team-hover-text ul li {
  display: inline-block;
}

.team-section .team-slider .team-card .team-img .team-hover .team-hover-text ul li a {
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  line-height: 30px;
  margin: 0 2px;
}

.team-section .team-slider .team-card .team-img .team-hover .team-hover-text ul li a:hover {
  background: -webkit-gradient(linear, left top, right top, from(#e4665c), to(#d95978));
  background: linear-gradient(90deg, #e4665c 0%, #d95978 100%);
}

.team-section .team-slider .owl-dots {
  bottom: -30px;
  position: relative;
}

.team-section .team-slider .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background: #edbbc0 !important;
  margin: 0 5px;
  border-radius: 10px;
}

.team-section .team-slider .owl-dots .owl-dot.active span {
  background: #de7880 !important;
}

/* Team CSS End */
/* Newsletter CSS Start */
.newsletter-two {
  background: -webkit-gradient(linear, left top, right top, from(#d95978), to(#e4665c)) !important;
  background: linear-gradient(90deg, #d95978 0%, #e4665c 100%) !important;
}

.newsletter-two .form-control {
  background: #ed9b96 !important;
}

.newsletter-two .newsletter-form .default-btn {
  color: #e4665c !important;
}

.newsletter-two .newsletter-form .default-btn:hover {
  background: #ed9b96 !important;
  color: #ffffff !important;
}

.newsletter-two .newsletter-form .validation-danger {
  color: #ffffff;
}

/* Newsletter CSS End */
/* Footer CSS Start */
.footer-style-five {
  background: -webkit-gradient(linear, left top, right top, from(#e87d74), to(#aa73b1));
  background: linear-gradient(90deg, #e87d74 0%, #aa73b1 100%);
}

.footer-style-five .footer-logo {
  margin-bottom: 8px;
}

.footer-style-five .footer-widget .footer-social a i {
  background: #eb9089;
}

.footer-style-five .footer-widget p {
  margin-bottom: 27px;
}

.footer-style-five .footer-widget ul li {
  color: #ffffff;
}

.footer-style-five .copyright-area {
  border-top: 1px solid #ee9e97;
}

/* Footer CSS End */
/*----- Creative Agency Page One End -----*/
/*----- Creative Agency Page Two Start -----*/
/* Custom CSS Start */
.color-red {
  color: #f71c0a !important;
}

/* Custom CSS End */
/* Navbar CSS Start */
.nav-style-three.is-sticky {
  background: -webkit-gradient(linear, left top, right top, from(#ef0a5e), to(#f71c0a));
  background: linear-gradient(90deg, #ef0a5e 0%, #f71c0a 100%);
}

/* Navbar CSS End */
/* Banner CSS Start */
.banner-bg-five {
  /* background-image: url(../img/creative-agency/); */
  position: relative;
  height: 100vh;
}

.banner-bg-five::before {
  position: absolute;
  content: '';
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, rgba(247, 28, 11, 0.85)), to(rgba(239, 10, 94, 0.85)));
  background: linear-gradient(0deg, rgba(247, 28, 11, 0.85) 25%, rgba(239, 10, 94, 0.85) 100%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* Banner CSS End */
/* About CSS Start */
.about-style-two .about-image::before, .about-style-two .about-image::after {
  display: none;
}

.about-style-two .about-btn a {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, rgba(247, 28, 11, 0.85)), to(rgba(239, 10, 94, 0.85))) !important;
  background: linear-gradient(0deg, rgba(247, 28, 11, 0.85) 25%, rgba(239, 10, 94, 0.85) 100%) !important;
}

.about-style-two .about-btn a:hover {
  color: #ffffff !important;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, rgba(239, 10, 94, 0.85)), to(rgba(247, 28, 11, 0.85))) !important;
  background: linear-gradient(0deg, rgba(239, 10, 94, 0.85) 25%, rgba(247, 28, 11, 0.85) 100%) !important;
}

/* About CSS End */
/* Service CSS Start */
.service-style-five .service-card::before {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, rgba(247, 28, 11, 0.85)), to(rgba(239, 10, 94, 0.85)));
  background: linear-gradient(0deg, rgba(247, 28, 11, 0.85) 25%, rgba(239, 10, 94, 0.85) 100%);
}

/* Service CSS End */
/* Price CSS Start */
.price-style-four {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, rgba(247, 28, 11, 0.85)), to(rgba(239, 10, 94, 0.85)));
  background: linear-gradient(0deg, rgba(247, 28, 11, 0.85) 25%, rgba(239, 10, 94, 0.85) 100%);
}

/* Price CSS End */
/* Team CSS Star */
.team-style-two .team-slider .team-card .team-img .team-hover {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, rgba(247, 28, 11, 0.85)), to(rgba(239, 10, 94, 0.85)));
  background: linear-gradient(0deg, rgba(247, 28, 11, 0.85) 25%, rgba(239, 10, 94, 0.85) 100%);
}

/* Team CSS End */
/* Newsletter CSS Start */
.newsletter-three {
  background: #003041 !important;
}

.newsletter-three .form-control {
  background: #597883 !important;
}

.newsletter-three .newsletter-form .default-btn:hover {
  background: #597883 !important;
}

/* Newsletter CSS End */
/* Footer CSS Start */
.footer-style-six {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(25%, rgba(247, 28, 11, 0.85)), to(rgba(239, 10, 94, 0.85)));
  background: linear-gradient(0deg, rgba(247, 28, 11, 0.85) 25%, rgba(239, 10, 94, 0.85) 100%);
}

/* Footer CSS End */
/*----- Creative Agency Page Two End -----*/
/* Back To Top  CSS Start */
.top-btn {
  color: #000000;
  position: fixed;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 35px;
  z-index: 9;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

/* Back To Top  CSS End */
/* Preloader CSS Start */
.loader-content {
  background: #06a1e9;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  left: 0;
  top: 0;
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
          animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
          animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* Preloader CSS End */
/* Animation CSS Start */
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes translateY {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes translateY {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes translateX {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes translateX {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(240, 52, 52, 0.7);
            box-shadow: 0 0 0 0 rgba(240, 52, 52, 0.7);
  }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(240, 52, 52, 0);
            box-shadow: 0 0 0 10px rgba(240, 52, 52, 0);
  }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(240, 52, 52, 0);
            box-shadow: 0 0 0 0 rgba(240, 52, 52, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(240, 52, 52, 0.7);
            box-shadow: 0 0 0 0 rgba(240, 52, 52, 0.7);
  }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(240, 52, 52, 0);
            box-shadow: 0 0 0 10px rgba(240, 52, 52, 0);
  }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(240, 52, 52, 0);
            box-shadow: 0 0 0 0 rgba(240, 52, 52, 0);
  }
}

/* Animation CSS End */
