/* user-profile-batch */
.user-profile-batch{
  position: absolute;
  right: 10px;
  z-index: 99999;
  top: 25px;
  background: #ffffff;
  padding: 10px 15px 10px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

}
.user-profile-batch a{
  color: #8a78ff;
  padding-left:5px ;
}
.user-profile-batch i{
  color: #8a78ff
}
/* ----------------- */
.home-screen-main-div {
  width: 100%;
  height: max-content;

  margin: auto;
  overflow: auto;
}

.card-row-first {
  display: flex;
  width: 85%;
  margin: auto;
  margin-top: 40px;
}
.Progress-card {
  display: flex;
  width: 85%;
  margin: auto;
  margin-top: 40px;
}

:root {
  --red: hsl(0, 78%, 62%);
  --cyan: hsl(180, 62%, 55%);
  --orange: hsl(34, 97%, 64%);
  --blue: hsl(212, 86%, 64%);
  --varyDarkBlue: hsl(234, 12%, 34%);
  --grayishBlue: hsl(229, 6%, 66%);
  --veryLightGray: hsl(0, 0%, 98%);
  --weight1: 200;
  --weight2: 400;
  --weight3: 600;
}

.attribution {
  font-size: 11px;
  text-align: center;
}
.attribution a {
  color: hsl(228, 45%, 44%);
}

h1:first-of-type {
  font-weight: var(--weight1);
  color: var(--varyDarkBlue);
}

h1:last-of-type {
  color: var(--varyDarkBlue);
}

@media (max-width: 400px) {
  h1 {
    font-size: 1.5rem;
  }
}

.header {
  text-align: center;
  line-height: 0.8;
  margin-bottom: 50px;
  margin-top: 100px;
}

.header p {
  margin: 0 auto;
  line-height: 2;
  color: var(--grayishBlue);
}

.box p {
  color: var(--grayishBlue);
}

.box {
  border-radius: 5px;
  box-shadow: 0px 30px 40px -20px var(--grayishBlue);
  padding: 30px;
  margin: 20px;
}

img {
  float: right;
}
/* contest-section */
.contest-title-heading {
  color: black;
  font-weight: 900;
  padding: 0px 20px 0px;
}

.contest-div {
    display: grid;
    width: 90%;
    margin:auto;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the width as needed */
    grid-gap: 100px; 
}
.contest-div .contest-card-component{
    display: flex;
  justify-content: center;
}
/* --------------------- */

@media (max-width: 450px) {
  .box {
    height: 200px;
  }
}

@media (max-width: 950px) and (min-width: 450px) {
  .box {
    text-align: center;
    height: 180px;
  }
}

.cyan {
  border-top: 3px solid var(--cyan);
}
.red {
  border-top: 3px solid var(--red);
}
.blue {
  border-top: 3px solid var(--blue);
}
.orange {
  border-top: 3px solid var(--orange);
}

h2 {
  color: var(--varyDarkBlue);
  font-weight: var(--weight3);
}

@media (min-width: 950px) {
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row2-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-down {
    position: relative;
    top: 150px;
  }
  .box {
    width: 20%;
  }
  .header p {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .card-row-first {
    display: block;
  }
  .contest-div{
    display: block;
    
  }
}
