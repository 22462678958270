.loginmain-outer-box {
  width: 100%;
  background: #000;
  margin: auto;
  padding: 50px 24px 24px;
  height: 100vh;
}
.signupContainer {
  width: 96%;
  margin: auto;
  height: 90vh;
  background: #0f1b21;
  text-align: center;
  display: flex;
  /* border-radius: 10px; */
}

.now-login-box {
  width: 25%;
}
.image-section-banner {
  width: 75%;
  background-image: url("../../image/vectors/Aiman1.jpg");
 
}
.signuptopLogo {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
 
  
}
.signuptopLogo .logotext-loginpage{
  color: white;
  display: flex;
  font-weight: 600;
 font-size: 28px;
}

.signuptopLogo span{
  
 background-clip: text;
 -webkit-background-clip: text;
 color: transparent;
 background-image: linear-gradient(45deg,#ff0f0f,#8f68ff,#a2bcff,#8f68ff,#ff0000);
 animation: tagline 2s ease infinite;
 background-size: 200%;
}
@keyframes tagline{
  to{
      background-position: 200%;
  }
 }


.signup_mainbox {
 
  text-align: center;
  width: 80%;
  margin: auto;
  background: transparent;
  border-radius: 2px;
}
.signup_mainbox .signup_heading {
  width: 100%;
  margin: auto;
  text-align: center;
  /* background: #000; */
  border-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #1e2d34;
}
.signup_heading h2 {
  font-family: "Roboto Condensed", sans-serif;
  color: #3b3b3b;
  padding-top: 4px;
  font-weight: 400;
  line-height: 30px;
}
.signup_heading p {
  font-size: 10px;
  color: #50636c;
}
.signup_with_google {
  width: 90%;
  margin-top: 20px;
  background: #f1fafd;
  padding: 14px;
  border: none;
  letter-spacing: 2px;
}
.signup_mainbox form {
  text-align: left;
  padding-left: 8px;
}
.signup_mainbox form p {
  font-size: 14px;
  color: #a3a3a3;
  margin-left: 5px;
}
.signup_mainbox form .inputBoxandIcon {
  display: flex;
  justify-content: space-between;
  /* background: #000; */
  border: 1px solid #002945;
  border-radius: 5px;
  width: 95%;
  padding: 1px;
  margin: auto;
  margin-bottom: 26px;
}
.signup_mainbox form .inputBoxandIcon:focus-within{
    border-bottom: 1px solid #50636c;
}


.inputBoxandIcon i {
  color: #a3a3a3;
  font-size: 17px;
  padding: 15px;
}
.signup_mainbox form .inputBoxandIcon input {
  width: 95%;
  padding: 10px;
  font-size: 16px;
  outline: none;
  border: none;
  margin-right: 5px;
  background: transparent;
  color: white;
}

.signup_mainbox form .formBtns {
  width: 95%;
  margin: auto;
  border-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e7e7e7;
  padding: 14px;
}
.frgtPswdDiv {
  margin-top: 14px;
  margin-bottom: 5px;
  text-align: center;
}
.frgtPswdDiv a {
  text-decoration: none;
  color: #7bc8fc;
}
#loginmsg {
  padding: 14px;
  border-radius: 5px;
  width: 30%;
  margin: auto;
  display: none;
}
.signup_mainbox form .formBtns .signupBtn {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background: #7d69ff;
  color: #ffffff;
}
.signup_mainbox form .LoginDirect {
  width: 95%;
  margin: auto;
  text-align: center;
  padding: 5px;
}
#userVarificationCodeSec {
  display: none;
}

/* loader */
:root {
  --main-color: #ffffff;
}
.loader {
  width: 100px;
  height: 30px;
  perspective: 1000px;
  margin: 0 auto;
  position: relative;
}
.loader .inner_loader {
  background: var(--main-color);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.loader .inner_loader:nth-child(1) {
  animation: rollInOut 3s 500ms ease infinite;
}
.loader .inner_loader:nth-child(2) {
  animation: rollInOut 3s 1s ease infinite;
}
.loader .inner_loader:nth-child(3) {
  animation: rollInOut 3s 1.5s ease infinite;
}

.signup_mainbox form .inputBoxandIcon select {
  width: 95%;
  padding: 10px;
  font-size: 16px;
  border: none;
  background: transparent;
  border-radius: 5px;
  margin-right: 5px;
  color: white;
}
.signup_mainbox form .inputBoxandIcon select option{
  background: #000;
  border: none;
}

@keyframes rollInOut {
  0% {
    transform: translateX(-100px) scale(0);
  }
  30%,
  70% {
    background: var(--main-color);
    transform: translateX(0) scale(1);
  }
  100% {
    transform: translateX(100px) scale(0);
  }
}
/* ------ */


@media (max-width: 1024px) {
  .signupContainer{
    display: block;
  }
  .now-login-box{
    width: 100%;
  }
  .image-section-banner{
    display: none;
  }
}