@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
/* Apply to the entire page */
/*  */
body{
    overflow-x: hidden;
}

.fa-solid.fa-spinner.fa-spin.fa-xl {
  color: #7d69ff;
  display: none;
  text-align: center;
  align-items: center;
  margin: 35px;
  /* font-size: 34px; */
}
.Mycourse-main-div {
  display: flex;
}
.Mycourses-sidebar {
  height: 100vh;
  width: 400px;
  display: flex;
  /* background: #000; */
  margin: 2px 2px 0px;
}
.mycourse-sidebar-content {
  width: 0%;
  background: #f0f2f8;
  display: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.mycourse-sidebar-content.active {
  width: 100%;
  display: block;
  overflow: auto;
  padding:2px;
  z-index: 9;
}
.mycourse-sidebar-content ul h6 {
  padding: 3px;
  margin: 3px;
  border-radius: 10px;
  color: rgb(70, 147, 255);
  background: #ffffff;

  text-align: left;
}
.mycourse-sidebar-content .Top-header-sidebar-mycourse {
  background-image: url("../../image/vectors/Leonardo_Phoenix_practcodecom_is_my_online_learning_platform_f_0.jpg");
  background-size: cover;
  color: white;
  font-weight: 700;
}
.lession-heading {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.lession-heading i {
  font-size: 18px;
}
.mycourse-sidebar-content h6,.mycourse-sidebar-content ul {
  padding: 12px;
  margin: 5px;
  border-radius: 10px;
  background: #ffffff;
}
.mycourse-sidebar-content ul li {
  padding: 2px 11px;
  margin-top: 8px;
  list-style: none;
  background: #f0f2f8;
  border-radius: 10px;
  text-align: left;
  display: none;
  cursor: pointer;
}
.mycourse-sidebar-content ul.show li {
  display: flex;
}
.mycourse-sidebar-content ul.show li span{
  display: none;
}
.mycourse-sidebar-content ul.show li span.active{
  display: block;
  font-size: 15px;
  padding-right: 5px;
  color: #7d69ff;
}

.mycourse-sidebar-toggle-btn {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.mycourse-sidebar-toggle-btn button {
  background: #f0f2f8;
  border: none;
  color:#1e2838;
  cursor: pointer;
  border-top-right-radius: 42px;
  border-bottom-right-radius: 42px;
}
.mycourse-sidebar-toggle-btn button i {
  padding: 14px;
}

/* mycourse body main div  */
.mycourse-body-main-div {
  width: 99%;
  padding: 14px;
  /* margin: 25px; */
  height: 90vh;
  overflow: auto;
  
}
.mycourse-body-main-div .typedtext {
  text-wrap: wrap;
}

.Stoptypingbtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #ffffff;
  /* color: #000000; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.Stoptypingbtn i {
  /* font-size: 30px; */
}

/* ---------mycourse-internal-quize-------- */
.mycourse-internal-quize {
  width: 100%;
  margin: auto;
  margin-top: 35px;
  margin-bottom: 35px;
  padding: 15px;
  /* background: #f0f2f8; */
  border-radius: 10px;
}

.Solve-Internalquiz-btn {
  background: #7d69ff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 50px;
}
/* ........................................... */
/* Image diplay */
p,h2,h3 {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align text to the start */
}
p > img ,h2 > img,h3 > img{
  margin-bottom: 10px; /* Add space between the image and the text */
  margin-left: 15px;
  width: 500px; /* Adjust the image size */
  height: auto; /* Maintain aspect ratio */
}



/* -0000000- */

@media (max-width: 767px) {
  .Mycourses-sidebar {
    height: 100vh;
    width: 0px;
    position: absolute;
  }
  .Mycourses-sidebar.active-for-responsive {
    width: 300px;
  }
  .typedtext {
    text-align: left;
  }
}



/* my coursed banner */
.mycourses-banner-section h1{
  font-size: 25px;
  text-align: center;
 font-weight: 600;
}


/* ---------------- */
/* floating button */
.practcode-ai-bot{
  position:fixed;
  bottom: 20px;
  right: 20px;
  /* background: #000; */
}
.practcode-ai-bot .bot-room{
  display: none;
  height: 500px;
  width: 300px;
  margin: 10px;
  /* background: rgb(220, 247, 255); */
  padding: 14px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* border: 2px solid black; */
  background: 
  #f0f2f8
  

}
.practcode-ai-bot .bot-room.active{
  display: block;
}
.practcode-ai-bot  .boot-angle-btn-div{
  text-align: right;
 background:transparent;
}
.practcode-ai-bot .floating-ai-button{
  background: #1e2838;
  color: white;
  font-weight: 700;
  border-radius: 10%;
  padding: 12px 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.practcode-ai-bot .bot-room .boot-room-upper-nav{
  /* background:#ffffff; */
  color: #fff;
  background-image: url('/src/Assets/image/vectors/Leonardo_Phoenix_practcodecom_is_my_online_learning_platform_f_0.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  padding: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}
.bot-room .boot-room-body{
  /* background: white; */
  overflow-y: auto;
  padding: 5px;
  height: 400px;
  margin: 14px 0px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

}
.bot-room .boot-room-body .body-placeholder{
  /* background: #000; */
  /* height: 100%; */
  /* text-align: center; */
  display: flex;
  align-items: center;

}

/* .body-placeholder i{
  background: #bebebe;
  padding: 5px 7px;
  border-radius: 50%;
} */


/*topicexplanation-box  */
.topicexplanation-box{
  background: #ffffff;
  padding: 14px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
}

.explanation-inner-box{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  padding: 14px;
  border-radius: 5px;
}
.explanation-inner-head{
  background: #f0f2f8;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  border-left:5px solid #1e2838;
  border-top-left-radius:0px ;
  width:100% ;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.explanation-inner-head p.explanation-inner-text-data{
  padding: 0px 10px 0px;
  margin: 0;
  margin-top:2px ;
  color: black;
}
.explanation-inner-body{
  padding: 14px;
}

.inner-box-upper-main{
  display: flex;
}

.inner-box-upper-main i.close-the-open-query{
  padding: 14px;
  cursor: pointer;
}


/*explanation-example-btn  */
.explanation-example-btn{
  background: #1e2838;
  color: white;
  padding: 2px 6px 2px;
  font-size: 9px;
  border-radius: 6px;
}
/* code-example-box */
.code-example-box{
  padding: 14px;
  
}
.code-example-box code{
  /* background: #000; */
  padding: 14px;
}
