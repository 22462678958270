/* change in nav  */
.navtaboptions .course-details{
    /* border-bottom: 2px dotted rgb(163, 163, 163); */
}

/*  */
.all-courses-div-class{
    width:90%;
    margin: auto;
    height: 100vh;
    overflow: auto;

}
.all-courses-main-top-div{
    width: 80%;
   
    margin: auto;
    display: flex;
    background: #f0f2f8;
}
.all-courses-main-top-div .div-section-first{
    width: 70%;
}
.div-section-first{
    padding: 24px;
}
.div-section-first p{
    padding: 15px 0px;
    letter-spacing: 2px;
}
.div-section-first h2{
    width: fit-content;
    border-bottom: 3px dotted rgb(118, 216, 255);
}
.all-course-seacrch-box-div{
    background: #ffffff;
    padding: 15px;
    width: 75%;
    display: flex;
    justify-content: space-between;
}
.all-course-seacrch-box-div input{
    border: none;
    font-size: 16px;
    background: transparent;
    color: #363636;
    outline: none;
}
.all-course-seacrch-box-div button{
    border: none;
    background: #000;
    color: #ffffff;
    padding: 14px;
    font-size: 14px;
    letter-spacing: 2px;
    border-radius: 2px;
    cursor: pointer;

}
.all-courses-search-filtersdiv{
    border-radius: 5px;
    margin: 14px 0px;
    padding: 8px;
    background: #f7f7f7;
    height: fit-content;
    border-left: 5px solid #e9ab72;
    cursor: pointer;
}

.all-courses-main-top-div .div-section-second{
    width: 30%;
    /* background: #000; */
}

.div-section-second img{
    width: 100%;
    justify-content: center;
    align-items: center;

}

/* all course lists */
.all-courses-display{
    width: 80%;
    margin: auto;
    border-right: 2px solid #686da4;
}
.individual-course-card{
    width: 95%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    /* background: #000; */
}
.individual-course-card .card-image{
    width: 35%;
    padding: 14px;
}
.card-image img{
    width: 100%;
    height: max-content;
}
.individual-course-card .card-details{
    width: 60%;
    padding: 14px;
}
.card-details .heading-and-price{
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 8px;
}
.heading-and-price .heading{
    background: rgb(240, 240, 240);
    border-radius: 5px;
    padding: 8px;
    letter-spacing: 2px;
    font-weight: 600;
}
.card-details .heading-and-price span{
    font-weight: 700;
}
.card-details .course-title{
    width: 60%;
    padding: 12px 0;
    font-weight: 600;
    letter-spacing: 2px;
}
.card-details .card-other-details{
    display: flex;
    padding: 0 0 10px;
    justify-content: space-between;
}
.card-other-details i{
    color: gold;
    padding: 5px;
}

.card-details button{
    background: transparent;
    border: none;
    font-weight: 700;
    cursor: pointer;
    font-size:16px ;
}

/* for mobile view */
@media only screen and (max-width: 950px){
   .all-courses-main-top-div{
    width: 100%;
    display: block; 
    }
    .div-section-first{
    width: 150%;
    /* background: #000; */
    }

    .all-course-seacrch-box-div{
        width: 150%;
        display: block;
        justify-content: none;

    }
    .all-course-seacrch-box-div button{
        margin-top:12px ;
    }
    .div-section-first h2{
        width: auto;
    }

    .div-section-second{
        width: 100%;
        display: none;
    }

    /* all course list */
    .all-courses-display{
        width: 100%;
    }
    .individual-course-card{
        display: block;
    }
    .individual-course-card .card-image{
        width: 100%;
    }
    .individual-course-card .card-details{
        width: 100%;
    }
    .card-details .course-title{
        width: 100%;
    }
    .card-other-details p{
        font-size: 14px;
    }
}