*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* font-family: */
}

/* hamare toggle icon style */
.hamburger {
    cursor: pointer;
  }
  
  .hamburger input {
    display: none;
  }
  
  .hamburger svg {
    /* The size of the SVG defines the overall size */
    height: 3em;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line {
    fill: none;
    stroke: white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line-top-bottom {
    stroke-dasharray: 12 63;
  }
  
  .hamburger input:checked + svg {
    transform: rotate(-45deg);
  }
  
  .hamburger input:checked + svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
  }
  
/* ------------ */


/* Practcode Navbar */
.PractcodeNav{
    /* background: #2196f3; */
    background: #000000;
    display: flex;
  
    
    
}
.PractcodeNavsidebartoggleBtn{
    
    padding: 7px;
    background: transparent;
    border: none;
}
.PractcodeNavsidebartoggleBtn i{
    cursor: pointer;
    color: #796aff;
    font-size: 25px;
    margin: 5px;
}
.PractcodeNav_heading_logo{
    /* color: #ffffff; */
    color: #2196f3;
    padding: 10px 10px 10px;

    /* font-size: 22px; */
    margin-left: 2%;
    font-weight: 600;
    display: flex;
}
.PractcodeNav_heading_logo img{
    width: 140px;
    height: 42px;
    background: #ffffff;
}
.PractcodeNav_options{
    display: flex;
    /* background: #000; */
    color: #ffffff;
    padding: 5px;
    margin-left: 2%;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    right:60px;
    
}
.PractcodeNav_options a{
    text-decoration: none;
    color: #c2e8ff;
    padding: 15px;
    font-size: 18px;
}
.PractcodeNav_options i{
    padding: 5px;
    font-size: 22px;
}
.PractcodeNav_options .PractcodeNav_notify{
    cursor: pointer;
    background:transparent;
    border-radius:11px ;
    height: min-content;
    width:fit-content;
    margin: 10px;
    display: flex;

}
.PractcodeNav_notify{
    color: #ffffff;
}
.PractcodeNav_notify #PractcodeNav_notify{
    /* background: red; */
    padding: 8px;
    color: #2196f3;


}
.practcodeNav_userprofile-batch{
    cursor: pointer;
    background: transparent;
    border: none;
    color: #ffffff;
    margin-left:15px;
    padding: 11px;
}

/* PractcodeNavSideBody section starts here */
.PractcodeNavSideBody{
    display: flex;
}


.PractcodeSide_nav{
   
    width: 0%;
    min-height: 100vh;
    max-height: max-content;
    background:#000000;
    /* background: #084c76; */
    z-index: 9999;
    

    position:absolute
}
.side-navigation-options li{
    
    visibility: hidden;
    transition: visibility 1s linear;
}
.side-navigation-options.activeSidenav li{
    
    visibility:visible ;
   

}
.PractcodeSide_nav.activeSidenav{
    width:15%;
   
}
.MycoursesBody{
    /* width: 100%; */
    height: 100vh;
    
}

/* courses topics display */
.PractcodeSideNavDetails{
    display: flex;
    border-bottom: solid 1px #494949;
    margin-top: 10px;
}
.PractcodeSideNavDetails .PractcodeSideNavDetailsIcon,
.PractcodeSideNavDetailsText{
    color: #ffff;
    font-size: 22px;
    padding: 15px;
}


/* PractcodeSidebar_userOptions style */
.PractcodeSidebar_userOptions{
    /* position: relative; */
    /* transition: all 0.50s; */
    /* top: 100px; */
    width: 0%;
    /* margin: auto; */
    /* text-align: center; */
    /* padding: 20px; */
    color: #000;
    display: none;
}
.PractcodeSidebar_userOptions.activeuser{
    width: 100%;
    display: block;
}
.PractcodeSidebar_userOptions .userOptions_section_sidenav{
    font-size: 15px;
    color: black;
    font-weight: 500;
    padding: 18px;
    letter-spacing: 2px;    

}
.userOptions_section_sidenav li{
    font-size: 14.5px;
    padding: 14px;
    padding-left: 15px;
    list-style: none;
    font-weight: 300;
    cursor: pointer;
    border-radius: 10px;

    
    
}
.userOptions_section_sidenav li:hover{
    background: rgb(242, 243, 244);
}
.userOptions_section_sidenav .active-option{
    background: rgb(242, 243, 244);
}
.userOptions_section_sidenav i{
    color: #796aff;
    margin-top: 2px;
    padding-right: 10px;
}
.userOptions_section_sidenav li a{
    text-decoration: none;
    color: #ffff;
}
.userOptions_section_sidenav li i{
    padding-right: 5px;
}

 
#PractcodeSideNavDetailsId{
    display: none;
} 

/*  */

/*  preloader  */
#preloader{
   
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 100;
}
 
.practcodeMainRoot{
    width: 100%;
    padding: 10px;
}

/* Practcode Profile dropdown */
.PractcodeProfiledropdown{
    position: absolute;
    background: #ffffff;
    width: auto;
    height: auto;
    right: 20px;
    top: 80px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    

    display: none;
    transition: height 0.5s ease;
    overflow: hidden;
}
.PractcodeProfiledropdown a{
    
    text-decoration: none;

    
}
.PractcodeProfiledropdown a button{
    background: transparent;
    color: #909294;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    padding: 5px;
    outline: none;
    border: none;
    text-align: left;
    margin: auto;
    cursor: pointer;
}

/* btns */
.PractcodeNav_login,
.PractcodeNav_signup{
    border: none;
    outline: none;
    margin-left: 20px;
    margin-right: 10px;
    background: #006caf;
    padding: 10px;
    color: white;
    height: 40px;
    font-size: 14px;
    margin-top: 8px;
    border-radius:5px ;
    cursor: pointer;

}
/* tab options */
.navtaboptions{
    /* background: #2196f3; */
    width: 30%;
    margin-left: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navtaboptions button{
    border: none;
    background: transparent;
}
.navtaboptions button a{
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
}
.navtaboptions button a.active{
    border-bottom: 2px dotted rgb(255, 255, 255);
    padding-bottom: 3px;
}
.navtaboptions .theamchangerbtn{
    background: #eeeeee;
    width: 10%;
    border-radius: 11px;
    height: 20px;
    text-align: left;
    align-items: left;
    cursor: pointer;
    transition: align-items 2s;
    

}
.navtaboptions .theamchangerbtn i.fa-sun{
    font-size: 20px;
    /* padding: 8px; */
    position: relative;
    
}
/*  */


/* checkauth */
#checkauth{
    display: none;
}




/* for mobile view */
@media only screen and (max-width: 950px){
    .navtaboptions{
        display: none;
    }
    .PractcodeNav_Searchbox,.PractcodeNav_options a{
        display: none;
    }
    .PractcodeNav_options{
        right: 10px;
        padding: 0px;
    }
    .PractcodeNav_heading_logo,.PractcodeNavsidebartoggleBtn i{
     font-size: 14px;
     
    }
    .practcodeMainRoot.activeSidenav{
        display: none;
    }
    
    /* side navbar responsive */
    
    .PractcodeSide_nav.activeSidenav{
        width: 100%;
    }
    .MycoursesBody.activeSidenav{
        display: none;
    }
    .PractcodeNav_login,.PractcodeNav_signup{
        display: none;
    }
    
    /*PractcodeNav_options  */
    .PractcodeNav_options{
        display: none;
    }
    /*  */
    .PractcodeNav_heading_logo{
        padding: 8px;
       
        justify-content: center;
        align-items: center;
    }

   
}
@media only screen and (max-width: 410px){
    .PractcodeNav_notify,.PractcodeNav_notify i,#PractcodeNav_notify{
        display: none;
    }
}   