/* ---------------------- */
.contest-completed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f5f5f5;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.contest-completed-message {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contest-completed-message h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #4CAF50; /* Green color for success */
}

.contest-completed-message p {
  font-size: 1.2rem;
  color: #777;
}

/* ---------------------- */


.header-div{
  position: fixed;
  width: 94%;
  /* z-index: 9999; */
  display: flex;
  background: #dbd5ff;;
  padding: 10px;
  justify-content: space-between;
  border-bottom-left-radius:10px ;
  border-bottom-right-radius:10px ;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.header-div h3{
  margin: 0;
  color: black;
}
/* .header-div .level-switcher{ */
  /* width: 50%; */
  /* background: #f0f0f0; */
/* } */
 /* Define the keyframes for the animation */
 @keyframes fadeIn {
  from { opacity: 0; } /* Start with opacity 0 */
  to { opacity: 1; } /* End with opacity 1 */
}
.user-batch{
  position: absolute;
  background: white;
  
  padding:14px;
  border-radius: 5px;
  border: 2px solid #7d69ff;
  width: 150px;
  right: 15px;
  top: 60px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  animation: fadeIn 0.2s ease-in-out forwards; 
  z-index: 99999;
}
.user-batch p{
  padding: 0px;
  margin: 0px;
}
.user-batch p.username{
  background: #f5f3ff;
  border-radius: 50px;
  padding: 0px 5px 0px;
  width: 120px;
  overflow: hidden;
  white-space: nowrap; /* Prevent text wrapping */
  text-overflow: ellipsis;
}
.user-batch p i{
  padding: 5px;
}


.level-switcher button{
  margin: 2px;
  padding: 5px 12px;
  background: #7d69ff;
  color: #dbd5ff;
  border-radius: 5px;
}
.header-Timer{
  border-radius: 5px;
  text-align: center;
  width: 170px;

  padding: 0px 14px;
}
.test-generatloader {
    display: block;
    --height-of-loader: 4px;
    --loader-color: #7d69ff;
    width: 90%;
    padding: 10px;
    margin: auto auto 10px;
    height: var(--height-of-loader);
    border-radius: 30px;
    background-color: rgba(0,0,0,0.2);
    position: relative;
  }
  
  .test-generatloader::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: moving 1s ease-in-out infinite;
    ;
  }
  .centered-div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  @keyframes moving {
    50% {
      width: 100%;
    }
  
    100% {
      width: 0;
      right: 0;
      left: unset;
    }
  }

/* for bg animated */

.Preloader-hackthon-container {
  width: 90%;
  height: 100%;
  background: lightblue;
  position: absolute;
  overflow: hidden;
}

.Preloader-hackthon-container::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, #7d69ff 10%, transparent 20%),
    radial-gradient(circle, transparent 10%, #7d69ff 20%);
  background-size: 30px 30px; /* Adjust the size of the pattern */
  animation: moveBackground 8s linear infinite; /* Adjust the animation duration and timing function */
}
.btn-profile-contest{
  padding: 0px 14px 0px;
  border-radius: 50px;
  border: 2px solid #b5aaff;


}

@keyframes moveBackground {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(20%, 20%);
  }
}
