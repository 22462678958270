.Mycourse-landing-main-div {
  padding: 15px;
  /* background: #cdebff; */
  height: auto;
  display: block;
}
.landing-bot-container {
  width: 20%;
  margin: auto;
  border: 1px solid #f1f1f1;
  border-radius: 100%;
  display:flex;
  padding: 1px 0px 0px; 
  height: 200px;

  justify-content:center;
  align-items: center;
  /* background: #000; */
  
  
}
.landing-bot-container img.robot1 {
  width: 99%;
 
}



.button-options-container {
  width: 80%;
  margin: auto;
  /* background: #000; */

  display: grid;
  gap: 10px; /* Space between buttons */
  padding: 10px;
  justify-content: center;
}

/* Default: Large screens (3+ columns) */
@media (min-width: 1024px) {
    .button-options-container {
      grid-template-columns: repeat(3, 1fr);
      
    }
  }
  
  /* Medium screens (2 columns) */
  @media (min-width: 600px) and (max-width: 1023px) {
    .button-options-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Small screens (1 column) */
  @media (max-width: 599px) {
    .button-options-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
/* bot-landing-otions-button */
/* From Uiverse.io by gharsh11032000 */
.bot-landing-otions-button {
  
  cursor: pointer;
  position: relative;
  padding: 5px 19px;
  color: #6d6d6d;
  border: 1px solid #cccccc;
  border-radius: 34px;
  background-color: transparent;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
}

.bot-landing-otions-button:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid #6f6f6f;
}


@media (max-width: 1024px) {
  .landing-bot-container {
    width: 80%;
  }
}