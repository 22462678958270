@media (max-width: 767px) {
  /* Custom CSS */
  p {
    font-size: 15px;
  }
  .pt-100 {
    padding-top: 50px;
  }
  .pb-70 {
    padding-bottom: 20px;
  }
  .section-title {
    text-align: center !important;
    margin: 0 auto 40px;
  }
  .section-title span {
    font-size: 15px;
  }
  .section-title h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  img {
    -webkit-animation: none !important;
            animation: none !important;
  }
  /* App landing Page CSS Start */
  /* Navbar CSS */
  .navbar {
    background-color: #069ee8;
    padding: 5px 15px;
    -webkit-box-shadow: 0 0 10px 0 #0000006b;
            box-shadow: 0 0 10px 0 #0000006b;
  }
  .navbar .navbar-nav {
    padding: 30px 0 20px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    padding: 0;
    margin: 0 0 15px;
  }
  .navbar .navbar-nav .nav-item .active::before {
    width: 30%;
    right: inherit;
  }
  .navbar .navbar-brand img {
    max-width: 100px;
  }
  .navbar button.navbar-toggler {
    border: 1px solid #fff;
    background: #fff;
    font-size: 15px;
  }
  .navbar .navbar-btn a {
    font-size: 14px;
    padding: 5px 15px;
    margin-bottom: 15px;
    margin-left: 0;
  }
  /* Home CSS */
  .main-banner {
    height: 100%;
  }
  .main-banner .banner-shape {
    display: none;
  }
  .main-banner .banner-text {
    text-align: center;
    padding: 120px 0 45px;
  }
  .main-banner .banner-text h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .main-banner .banner-text p {
    margin-bottom: 20px;
  }
  .main-banner .banner-text .banner-btn a {
    padding: 8px 16px;
    margin-right: 10px;
    font-size: 14px;
  }
  .main-banner .banner-img {
    position: relative;
    z-index: 0;
    margin: 0 auto 50px;
    max-width: 200px;
  }
  .main-banner .banner-img::after {
    display: none;
  }
  .main-banner .banner-img::before {
    display: none;
  }
  .main-banner .banner-img img {
    width: 100%;
  }
  /* Why Choose CSS */
  .why-choose::after {
    display: none;
  }
  .why-choose .section-title {
    margin: 0 auto 50px;
  }
  .why-choose .section-title p {
    font-size: 16px;
  }
  .why-choose::before {
    display: none;
  }
  .why-choose .why-choose-text {
    padding-top: 50px;
  }
  .why-choose .why-choose-text .media {
    margin-bottom: 25px;
  }
  .why-choose .why-choose-text .media i {
    font-size: 25px;
    left: 10px;
    top: 15px;
  }
  .why-choose .why-choose-text .media i::after {
    width: 45px;
    height: 45px;
    top: -10px;
    left: -10px;
  }
  .why-choose .why-choose-text .media .media-body {
    padding-left: 18px;
    font-size: 15px;
  }
  .why-choose .why-choose-text .media .media-body h3 {
    font-size: 18px;
  }
  .why-choose .why-choose-text .why-choose-btn {
    margin-top: 20px;
    text-align: center;
  }
  .why-choose .why-choose-text .why-choose-btn a {
    padding: 5px 10px 0px;
    margin-right: 5px;
  }
  .why-choose .why-choose-text .why-choose-btn a i {
    top: 12px;
    position: relative;
    left: 0;
    font-size: 20px;
    line-height: 1;
    left: -40px;
  }
  .why-choose .why-choose-text .why-choose-btn a p {
    font-size: 10px;
    padding-left: 30px;
  }
  .why-choose .why-choose-text .why-choose-btn a h5 {
    font-size: 12px;
    padding-left: 30px;
  }
  .why-choose .why-choose-img {
    max-width: 250px;
    margin: auto;
  }
  .why-choose .why-choose-img::before {
    display: none;
  }
  /* Features CSS */
  .feature-section .feature-card {
    padding: 60px 20px 30px;
  }
  /* Screenshoot CSS */
  .screenshots-section {
    padding-bottom: 40px;
  }
  .screenshots-section .screenshot-slider .screenshoot-img {
    margin-bottom: 0;
  }
  .screenshots-section .screenshot-slider .screenshoot-img img {
    max-width: 100%;
    width: unset;
    margin: auto;
  }
  .screenshots-section .screenshot-shape {
    display: none;
  }
  /* How Use CSS */
  .how-use::before {
    display: none;
  }
  .how-use .section-title p {
    font-size: 15px;
  }
  .how-use .how-use-card i {
    font-size: 40px;
  }
  .how-use .how-use-card h3 {
    font-size: 20px;
  }
  .how-use p {
    font-size: 16px;
    padding: 0 10px;
  }
  .how-use .how-use-slider::after, .how-use .how-use-slider::before {
    display: none;
  }
  .how-use .how-use-img img {
    max-width: 100%;
    width: unset;
    margin: auto;
  }
  /* Pricing CSS */
  .pricing-section {
    padding-top: 40px;
  }
  .pricing-section .plan-list ul {
    margin-bottom: 40px;
  }
  .pricing-section .plan-list ul li {
    padding: 0;
    font-size: 14px;
  }
  .pricing-section .plan-list ul li a {
    font-size: 14px;
    padding: 10px 14px;
  }
  .pricing-section .price-card {
    padding: 40px 20px;
  }
  .pricing-section .price-card p {
    font-size: 17px;
  }
  .pricing-section .price-card .price-feature ul {
    padding-left: 0;
    text-align: center;
  }
  .pricing-section .price-card .price-feature ul li {
    font-size: 16px;
  }
  .pricing-section .price-card .price-btn a {
    font-size: 15px;
    padding: 15px 40px;
  }
  /* Testimonial CSS */
  .testimonial-section {
    padding-bottom: 50px;
  }
  .testimonial-section .section-title h2 {
    font-size: 20px;
  }
  .testimonial-section .testimonial-shape {
    display: none;
  }
  .testimonial-section .testimonial-slider::before {
    font-size: 30px;
    top: 80px;
    right: 20px;
  }
  .testimonial-section .testimonial-slider .testimonial-item {
    padding: 45px 20px 45px;
    text-align: center;
  }
  .testimonial-section .testimonial-slider .testimonial-item img {
    margin: 0 auto 10px;
  }
  .testimonial-section .testimonial-slider .testimonial-item p {
    margin: auto;
    max-width: 235px;
  }
  .testimonial-section .testimonial-img .client-img img {
    -webkit-animation: none;
            animation: none;
  }
  .testimonial-section .testimonial-img .client-img :nth-child(1) {
    top: 15%;
    left: 15%;
    max-width: 30px;
  }
  .testimonial-section .testimonial-img .client-img :nth-child(2) {
    bottom: 10%;
    left: 27%;
    max-width: 30px;
  }
  .testimonial-section .testimonial-img .client-img :nth-child(3) {
    top: 10%;
    left: 30%;
  }
  .testimonial-section .testimonial-img .client-img :nth-child(4) {
    left: 0;
    top: 25%;
    max-width: 50px;
    right: 0;
    margin: auto;
  }
  .testimonial-section .testimonial-img .client-img :nth-child(5) {
    right: 20%;
    top: 10%;
    width: 40px;
  }
  .testimonial-section .testimonial-img .client-img :nth-child(6) {
    right: 10%;
    bottom: 10%;
  }
  .testimonial-section .testimonial-slider .testimonial-item {
    padding: 45px 0px 45px 0;
  }
  .testimonial-section .testimonial-slider .owl-prev, .testimonial-section .testimonial-slider .owl-next {
    width: 40px;
    height: 40px;
    font-size: 12px !important;
    left: -70px;
    right: 0;
    margin: auto;
  }
  .testimonial-section .testimonial-slider .owl-next {
    left: 0;
    right: -35px;
  }
  /* Newsletter CSS */
  .newsletter .newsletter-area {
    padding: 30px;
    border-radius: 20px;
    text-align: center;
  }
  .newsletter .newsletter-area .newsletter-text {
    padding-right: 0;
  }
  .newsletter .newsletter-area .newsletter-text p {
    max-width: 300px;
    margin: 0 auto 20px;
  }
  .newsletter .newsletter-area .newsletter-form .form-control {
    margin-bottom: 10px;
  }
  .newsletter .newsletter-area .newsletter-form button {
    position: unset;
    border-radius: 30px;
  }
  .newsletter .newsletter-area .newsletter-form button:hover {
    border: 0;
  }
  /* Footer CSS */
  .footer-area {
    padding-top: 200px;
  }
  .footer-area .footer-widget {
    margin-bottom: 20px;
  }
  .footer-area .footer-widget h3 {
    margin-bottom: 20px;
  }
  .footer-area .footer-widget ul li {
    margin-bottom: 5px;
  }
  .footer-area .footer-widget ul :last-child {
    margin-bottom: 10px;
  }
  .footer-area .footer-widget .footer-logo img {
    margin-bottom: 15px;
  }
  .footer-area .footer-widget p {
    margin-bottom: 15px;
  }
  .footer-area .footer-widget .footer-link {
    margin-bottom: 30px;
  }
  .footer-area .pl-90 {
    padding-left: 0;
  }
  .footer-area .copyright-area {
    text-align: center;
    margin-top: 10px;
  }
  .footer-area .copyright-area p {
    margin-bottom: 0;
    text-align: center;
    margin-top: 18px;
    line-height: 20px;
  }
  /* App landing Page CSS End */
  /* Sass landing Page CSS Start */
  /* Banner Section CSS */
  .banner-style-two .banner-text {
    padding: 120px 0 200px;
  }
  .banner-style-two .banner-image-two :nth-child(1) {
    max-width: 300px;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .banner-style-two .banner-image-two :nth-child(2) {
    max-width: 70px;
    border-radius: 0;
    bottom: 0;
    left: 0;
    right: -160px;
    margin: auto;
  }
  .banner-style-two .banner-image-two :nth-child(3) {
    display: none;
  }
  /* Trusted CSS */
  .trusted-company {
    padding-top: 50px;
  }
  /* Our Service CSS */
  .service-section {
    padding-top: 20px;
  }
  .service-section::before {
    display: none;
  }
  .service-section .service-card {
    padding: 50px 5px 30px;
  }
  .service-section .service-card i {
    margin-bottom: 30px;
  }
  .service-section .service-card h3 {
    margin-bottom: 13px;
    font-size: 18px;
  }
  /* Why Choose CSS */
  .why-choose-two {
    padding-top: 0;
  }
  .why-choose-two .shapes {
    display: none;
  }
  /* Standard CSS */
  .standard-section {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .standard-section .standard-text {
    margin-bottom: 30px;
  }
  .standard-section .standard-img::before {
    display: none;
  }
  .standard-section .standard-shape {
    display: none;
  }
  /* Our Apps CSS */
  .our-apps .apps-shape {
    display: none;
  }
  .our-apps .our-apps-img {
    max-width: 250px;
    left: 0;
    right: 0;
    margin: 0 auto 30px;
  }
  .our-apps .our-apps-img::before {
    display: none;
  }
  .our-apps .our-apps-img img {
    width: 100%;
  }
  .our-apps .our-apps-text {
    text-align: center;
  }
  .our-apps .our-apps-text .our-apps-btn {
    text-align: center;
  }
  .our-apps .our-apps-text .our-apps-btn a {
    width: 200px;
    padding: 0 10px 10px;
    margin: 0 10px;
  }
  .our-apps .our-apps-text .our-apps-btn a p {
    font-size: 12px;
    top: -5px;
    position: relative;
  }
  .our-apps .our-apps-text .our-apps-btn a i {
    left: -60px;
    top: 15px;
  }
  .our-apps .our-apps-text .our-apps-btn a h5 {
    font-size: 14px;
    top: -5px;
    position: relative;
  }
  /* Counter CSS */
  .counter-section {
    padding-bottom: 200px;
  }
  .counter-section::before {
    height: 100%;
  }
  .counter-section .counter-img :nth-child(1) {
    max-width: 300px;
    bottom: 0px;
    margin: auto;
    left: 0;
    right: 0;
  }
  .counter-section .counter-img :nth-child(2) {
    right: 20%;
    bottom: 0;
    max-width: 70px;
  }
  .counter-section .counter-text i {
    font-size: 30px;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .counter-section .counter-text h2 {
    font-size: 30px;
  }
  /* Sass landing Page CSS End */
  /* Sass landing Page Two CSS Start */
  /* Banner CSS */
  .main-banner-two .banner-image-three {
    right: 0;
    max-width: 225px;
    z-index: 99;
    left: 0;
    margin: auto;
  }
  .main-banner-two .banner-image-three img {
    border-radius: 0;
  }
  .main-banner-two .banner-image-three :nth-child(2) {
    display: none;
  }
  .main-banner-two .banner-text {
    padding: 120px 0 220px;
  }
  .main-banner-two .banner-shape-two {
    display: none;
  }
  /* Service CSS Start */
  .service-style-two .service-shape {
    display: none;
  }
  .service-style-two .service-item h3 {
    font-size: 18px;
  }
  /* Feature CSS */
  .features-style-two {
    padding-bottom: 50px;
  }
  .features-style-two .section-title h2 {
    font-size: 20px;
  }
  .features-style-two .feature-text {
    padding-left: 10px;
  }
  .features-style-two .featues-shape {
    display: none;
  }
  .features-style-two .feature-image {
    margin-bottom: 30px;
  }
  /* Keep Update CSS */
  .keep-update .keep-shape {
    display: none;
  }
  .keep-update .keep-updating-text .keep-btn {
    text-align: center;
  }
  .keep-update .keep-updating-text .keep-btn a {
    margin: 0 10px;
  }
  .keep-update .keep-updating-text .keep-btn a i {
    left: -60px;
  }
  .keep-update .keep-updating-text p {
    text-align: center;
  }
  .keep-update .keep-img {
    max-width: 300px;
    margin: auto;
  }
  .keep-update .keep-img::before {
    left: 0;
  }
  /* Price Style Two */
  .price-style-two {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .price-style-two::before {
    background-color: #293767;
  }
  .price-style-two .price-shape {
    display: none;
  }
  .price-style-two .price-card .price-feature ul li {
    font-size: 14px;
  }
  /* Counter CSS */
  .counter-style-two::before {
    display: none;
  }
  .counter-style-two .counter-text {
    margin-bottom: 10px;
    padding: 20px;
  }
  /* Testimonial CSS */
  .testimonial-style-two .section-title h2 {
    font-size: 20px;
  }
  .testimonial-style-two .testimonial-slider-area::before {
    display: none;
  }
  .testimonial-style-two .testimonial-slider-area .testimonial-items {
    padding: 25px;
  }
  .testimonial-style-two .testimonial-slider-area .testimonial-items .client-info {
    float: none;
    padding-left: 0;
  }
  .testimonial-style-two .testimonial-slider-area .testimonial-items .client-text {
    padding: 0;
    text-align: left;
  }
  .testimonial-style-two .testimonial-slider-area .owl-prev {
    left: 0;
    top: inherit;
    font-size: 14px !important;
    width: 40px;
    height: 40px;
    line-height: 40px !important;
  }
  .testimonial-style-two .testimonial-slider-area .owl-next {
    right: 0;
    top: inherit;
    font-size: 14px !important;
    width: 40px;
    height: 40px;
    line-height: 40px !important;
  }
  /* Sass landing Page Two CSS End */
  /* Personal Portfolio CSS Start */
  /* Banner CSS */
  .banner-style-four {
    padding-top: 0;
  }
  .banner-style-four .banner-text span {
    font-size: 18px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .banner-style-four .banner-text p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .banner-style-four .banner-img-two {
    margin-bottom: 20px;
    padding-top: 0;
  }
  /* About CSS */
  .about-section .about-text {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 30px;
  }
  .about-section .about-text ul li {
    margin-right: 0;
  }
  .about-section .about-text .about-btn {
    text-align: center;
  }
  .about-section .about-text .about-btn a {
    margin: 0 5px;
    padding: 5px 20px;
  }
  .about-section .about-text h2 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 25px;
  }
  .about-section .about-shape {
    display: none;
  }
  .about-section .about-img .about-img-text p::before {
    display: none;
  }
  /* Service CSS */
  .service-style-three .service-items {
    padding: 30px 10px;
  }
  .service-style-three .service-items i {
    margin-bottom: 20px;
    width: 90px;
    height: 90px;
    line-height: 90px;
  }
  .service-style-three .service-items h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  /* Software CSS */
  .software-section {
    padding-bottom: 50px;
  }
  .software-section .software-shape {
    display: none;
  }
  /* Showcase CSS */
  .show-case-section::before {
    display: none;
  }
  .show-case-section .showcase-menu ul li {
    display: inline-block;
    margin-bottom: 10px;
    padding: 5px 10px;
    width: 200px;
  }
  .show-case-section .showcase-card img {
    width: 100%;
  }
  /* Testimonial CSS */
  .testimonial-style-three .testimonial-area::after {
    width: 100%;
  }
  .testimonial-style-three .testimonial-area .slick-prev::before, .testimonial-style-three .testimonial-area .slick-next::before {
    display: none;
  }
  /* Contact CSS */
  .contact-section .contact-form {
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .contact-section .contact-form h2 {
    font-size: 17px;
    margin-bottom: 26px;
  }
  .contact-section .contact-form .form-group .form-control {
    height: 40px;
    padding: 0 20px;
  }
  /* Footer CSS */
  .footer-style-four {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .footer-style-four .footer-widget .footer-btn a {
    margin-bottom: 10px;
  }
  /* Personal Portfolio  CSS End */
  /* Creative Agency  CSS Start */
  /* Navbar CSS */
  .nav-style-two {
    background: -webkit-gradient(linear, left top, right top, color-stop(20%, #e4665c), to(#9b5ba3));
    background: linear-gradient(90deg, #e4665c 20%, #9b5ba3 100%);
  }
  /* Banner CSS */
  .banner-style-three .banner-img-three {
    -webkit-animation: none;
            animation: none;
    max-width: 300px;
    margin: auto;
    right: 0;
    left: 0;
    position: relative;
    z-index: 1;
  }
  /* About CSS */
  .about-us-section .about-image {
    margin-bottom: 30px;
  }
  .about-us-section .about-shape {
    display: none;
  }
  .about-us-section .about-text {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
  .about-us-section .about-text ul li {
    font-size: 15px;
  }
  /* Process CSS */
  .work-process .process-card {
    border-radius: 10px;
  }
  .work-process .process-card::before {
    width: 90%;
    height: 85%;
    top: 12px;
    border-radius: 10px;
  }
  /* Case Study CSS */
  .case-study-section .case-study-slider .case-items .case-study-text {
    padding: 50px 15px 30px !important;
  }
  .case-study-section .case-study-slider .case-items .case-study-text h3 a {
    font-size: 16px;
  }
  .case-study-section .case-study-slider .case-items .case-img .project-logo {
    width: 80px;
    height: 80px;
    bottom: -35px;
  }
  .case-study-section .case-study-slider .owl-dots {
    bottom: -20px !important;
  }
  /* Pricing Section */
  .price-style-three .price-card {
    padding: 20px;
  }
  .price-style-three .price-card .price-feature ul li {
    font-size: 14px;
  }
  /* Team CSS */
  .team-section .team-slider .team-card {
    width: 100%;
  }
  .team-section .team-slider .owl-dots {
    bottom: 0;
  }
  /* Case Study */
  .case-study-section .case-study-slider .owl-dots {
    bottom: 0;
  }
  .case-study-section .case-study-slider .case-items .case-study-text {
    padding: 70px 15px 30px;
  }
  /* Testimonial CSS */
  .testimonial-style-four {
    padding-top: 40px;
    padding-bottom: 135px;
  }
  .testimonial-style-four .testimonial-slider-wrapper .tsetimonial-card {
    padding: 0 15px;
  }
  .testimonial-style-four .testimonial-slider-wrapper .tsetimonial-card p {
    font-size: 14px;
  }
  .testimonial-style-four .testimonial-slider-wrapper::before {
    -webkit-clip-path: none;
            clip-path: none;
    height: 155%;
  }
  .testimonial-style-four .testimonial-slider-wrapper .owl-prev {
    left: 15px;
  }
  .testimonial-style-four .testimonial-slider-wrapper .owl-next {
    right: 15px;
  }
  /* Price CSS */
  .price-style-three .price-card {
    padding: 0 0 40px;
  }
  .price-style-three .price-card .price-feature ul {
    padding-left: 0;
  }
  /* Creative Agency  CSS End */
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Custom CSS */
  p {
    font-size: 15px;
  }
  .pt-100 {
    padding-top: 70px;
  }
  .pb-70 {
    padding-bottom: 40px;
  }
  .section-title {
    text-align: center !important;
    margin: 0 auto 50px;
  }
  .section-title span {
    font-size: 15px;
  }
  .section-title h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  /* Navbar CSS */
  .navbar {
    background-color: #069ee8;
    padding: 5px 15px;
    -webkit-box-shadow: 0 0 10px 0 #0000006b;
            box-shadow: 0 0 10px 0 #0000006b;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    padding: 0;
    margin: 0 10px;
    font-size: 15px;
  }
  .navbar .navbar-brand img {
    max-width: 100px;
  }
  .navbar .navbar-btn a {
    font-size: 14px;
    padding: 5px 15px;
    margin-left: 0;
  }
  /* Home CSS */
  .main-banner {
    height: 100%;
    
  }
  .main-banner .banner-shape {
    display: none;
  }
  .main-banner .banner-text {
    text-align: center;
    padding: 120px 0 40px;
  }
  .main-banner .banner-text h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .main-banner .banner-text p {
    margin: 0 auto 5px;
  }
  .main-banner .banner-text .banner-btn a {
    padding: 8px 16px;
    margin-right: 10px;
    font-size: 14px;
  }
  .main-banner .banner-img {
    position: relative;
    z-index: 0;
    margin: 0 auto 50px;
    max-width: 200px;
  }
  .main-banner .banner-img::after {
    display: none;
  }
  .main-banner .banner-img::before {
    display: none;
  }
  .main-banner .banner-img img {
    width: 100%;
  }
  /* Why Choose CSS */
  .why-choose::before {
    display: none;
  }
  .why-choose::after {
    z-index: -1;
  }
  .why-choose .section-title {
    margin: 0 auto 50px;
  }
  .why-choose .why-choose-text .media i {
    top: 20px;
    left: 35px;
  }
  .why-choose .why-choose-text .media .media-body {
    max-width: 550px;
  }
  .why-choose .why-choose-img {
    position: relative;
    max-width: 300px;
    margin: 0 auto 50px;
  }
  /* Screenshot CSS */
  .screenshots-section {
    padding-bottom: 60px;
  }
  .screenshots-section .screenshot-shape {
    display: none;
  }
  /* Price CSS */
  .pricing-section .price-card {
    padding: 50px 20px;
  }
  .pricing-section .price-card .price-feature ul {
    padding-left: 0;
  }
  .pricing-section .price-card .price-btn a {
    padding: 15px 30px;
  }
  /* How to Use */
  .how-use .how-use-slider::after {
    display: none;
  }
  /* Testimonial CSS */
  .testimonial-section {
    padding-bottom: 0;
  }
  .testimonial-section .testimonial-shape {
    display: none;
  }
  .testimonial-section .testimonial-slider::before {
    font-size: 70px;
    top: 80px;
    right: 20px;
  }
  .testimonial-section .testimonial-img .client-img :nth-child(2) {
    bottom: 40px;
    left: 28%;
  }
  /* Newsletter CSS */
  .newsletter .newsletter-area {
    padding: 40px;
  }
  .newsletter .newsletter-text {
    margin-bottom: 30px;
  }
  /* Footer CSS */
  .footer-area {
    padding-top: 220px;
  }
  .footer-area .footer-widget {
    margin-bottom: 30px;
  }
  .footer-area .footer-widget h3 {
    margin-bottom: 15px;
  }
  .footer-area .pl-90 {
    padding-left: 0;
  }
  .footer-area .copyright-area {
    margin-top: 40px;
  }
  .footer-area .copyright-area img {
    max-width: 36px;
  }
  /* Sass Landing CSS Start */
  /* Banner Section CSS */
  .banner-style-two .banner-text {
    padding: 120px 0 220px;
  }
  .banner-style-two .banner-text .banner-btn {
    margin-bottom: 20px;
  }
  .banner-style-two .banner-image-two :nth-child(1) {
    max-width: 330px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .banner-style-two .banner-image-two :nth-child(2) {
    max-width: 70px;
    border-radius: 0;
    bottom: 0;
    left: 0;
    right: -180px;
    margin: auto;
  }
  .banner-style-two .banner-image-two :nth-child(3) {
    display: none;
  }
  /* Why Choose CSS */
  .why-choose-two .why-choose-img {
    max-width: 450px;
  }
  .why-choose-two .why-choose-img::before {
    display: none;
  }
  .why-choose-two .shapes {
    display: none;
  }
  /* Standard CSS */
  .standard-section .standard-text {
    margin-bottom: 30px;
  }
  .standard-section .standard-text .section-title {
    margin: 0 auto 30px;
  }
  .standard-section .standard-shape {
    display: none;
  }
  /* Our Apss CSS */
  .our-apps .our-apps-text {
    text-align: center;
  }
  .our-apps .our-apps-text .section-title {
    margin: 0 auto 30px;
  }
  .our-apps .our-apps-text .our-apps-btn a i {
    left: -65px;
  }
  .our-apps .our-apps-img {
    position: relative;
    z-index: 0;
    right: 0;
    left: 0;
    margin: 0 auto 30px;
    max-width: 300px;
  }
  .our-apps .our-apps-img img {
    width: 100%;
  }
  .our-apps .apps-shape {
    display: none;
  }
  /* Counter CSS */
  .counter-section {
    padding-bottom: 350px;
  }
  .counter-section::before {
    height: 100%;
  }
  .counter-section .counter-img :nth-child(1) {
    right: 0;
    bottom: 0;
    max-width: 560px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .counter-section .counter-img :nth-child(2) {
    right: -200px;
    bottom: 0;
    max-width: 110px;
    left: 0;
    margin: auto;
  }
  /* Pricing CSS */
  .price-section-two {
    padding-top: 70px;
  }
  /* Sass Landing CSS End */
  /* Sass Landing Two CSS Start */
  /* Banner CSS */
  .main-banner-two .banner-image-three {
    right: 0;
    bottom: 0;
    max-width: 260px;
    left: 0;
    margin: auto;
  }
  .main-banner-two .banner-image-three :nth-child(2) {
    display: none;
  }
  .main-banner-two .banner-text {
    padding-bottom: 250px;
  }
  .main-banner-two .banner-shape-two {
    display: none;
  }
  /* Feature CSS */
  .features-style-two {
    padding-bottom: 70px;
  }
  .features-style-two .feature-text {
    padding-top: 50px;
  }
  .features-style-two .feature-text .section-title {
    margin: 0 auto 25px;
  }
  /* Keep Upadate CSS */
  .keep-update {
    padding-bottom: 0px;
  }
  .keep-update .keep-updating-text {
    text-align: center;
  }
  .keep-update .keep-updating-text .keep-btn a i {
    left: -65px;
  }
  .keep-update .keep-shape {
    display: none;
  }
  .keep-update .keep-img {
    max-width: 500px;
    margin: auto;
  }
  .keep-update .keep-img img {
    width: 100%;
  }
  .keep-update .keep-img::before {
    display: none;
  }
  /* Price Shape CSS */
  .price-style-two {
    padding-top: 70px;
    padding-bottom: 50px;
    background: #293767;
  }
  .price-style-two .price-shape {
    display: none;
  }
  /* Counter CSS */
  .counter-style-two {
    padding-top: 40px;
  }
  /* Testimonial CSS */
  .testimonial-style-two .testimonial-slider-area::before {
    display: none;
  }
  .testimonial-style-two .testimonial-slider-area .owl-prev {
    left: -30px;
    width: 35px;
    height: 35px;
    line-height: 35px !important;
    font-size: 14px !important;
  }
  .testimonial-style-two .testimonial-slider-area .owl-next {
    right: -40px;
    width: 35px;
    height: 35px;
    line-height: 35px !important;
    font-size: 14px !important;
  }
  /* Newsletter CSS */
  .newsletter-style-two {
    padding-top: 30px;
  }
  /* Sass Landing Two CSS End */
  /* Personal Portfolio  CSS Start */
  /* Navbar CSS */
  .navbar-area .navbar-btn {
    display: none;
  }
  /* Banner CSS */
  .banner-style-four {
    padding-top: 0;
  }
  .banner-style-four .banner-text {
    padding: 120px 0 35px;
    margin: 0 auto;
  }
  .banner-style-four .banner-text span {
    font-size: 20px;
  }
  .banner-style-four .banner-text h1 {
    font-size: 60px;
  }
  .banner-style-four .banner-text p {
    font-size: 18px;
    margin-bottom: 40px;
  }
  .banner-style-four .banner-text .banner-btn a {
    padding: 15px 30px;
  }
  /* About CSS */
  .about-section .about-img img {
    width: 100%;
  }
  .about-section .about-img .about-img-text p::before {
    display: none;
  }
  .about-section .about-text h2 {
    font-size: 35px;
    margin-top: 10px;
  }
  .about-section .about-shape {
    display: none;
  }
  /* Showcase CSS */
  .show-case-section .showcase-menu ul li {
    padding: 6px 15px;
    font-size: 14px !important;
  }
  /* Testimonial CSS */
  .testimonial-style-three .testimonial-area::after {
    width: 100%;
  }
  .testimonial-style-three .sin-testiText p {
    margin-top: 22px;
    max-width: 500px;
    margin: 22px auto;
  }
  .testimonial-style-three .sin-testiImage img {
    width: 67px;
    height: 67px;
  }
  .testimonial-style-three .slick-prev::before {
    color: #fff;
    border: 1px solid #fff;
  }
  .testimonial-style-three .slick-next::before {
    color: #fff;
    border: 1px solid #fff;
  }
  /* Contact CSS */
  .contact-section .contact-form {
    padding: 40px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .contact-section .contact-form h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .contact-section .contact-form .form-group .form-control {
    height: 45px;
  }
  /* Footer CSS */
  .footer-style-four {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  /* Personal Personal Two CSS End */
  /* Creative Agency CSS Start */
  /* Navbar CSS */
  .nav-style-two {
    background: -webkit-gradient(linear, left top, right top, color-stop(20%, #e4665c), to(#9b5ba3));
    background: linear-gradient(90deg, #e4665c 20%, #9b5ba3 100%);
  }
  /* Banner CSS */
  .banner-style-three .banner-img-three {
    right: 25px;
    max-width: 500px;
    position: relative;
    margin: 0 auto;
  }
  .banner-style-three .banner-text h1 {
    font-size: 55px;
    margin-bottom: 40px;
  }
  .banner-style-three .banner-text p {
    font-size: 16px;
    margin-bottom: 50px;
  }
  /* About CSS */
  .about-us-section .about-image {
    margin-bottom: 40px;
  }
  /* Case Study CSS */
  .case-study-section .case-study-slider .case-items .case-study-text h3 a {
    font-size: 20px;
  }
  /* testimonial CSS */
  .testimonial-style-four {
    padding-top: 50px;
  }
  .testimonial-style-four .testimonial-slider-wrapper .owl-next {
    right: 0;
  }
  .testimonial-style-four .testimonial-slider-wrapper .owl-prev {
    left: 0;
  }
  /* Pricing CSS */
  .price-style-three .price-card {
    padding: 20px;
  }
  .price-style-three .price-card .price-feature {
    padding: 0 20px;
  }
  /* Team CSS */
  .team-section {
    padding-bottom: 100px;
  }
  .team-section .team-slider .team-card {
    width: 100%;
  }
  /* Creative Agency CSS End */
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Custom CSS */
  .section-title h2 {
    font-size: 30px;
  }
  .pt-100 {
    padding-top: 70px;
  }
  .pb-70 {
    padding-bottom: 40px;
  }
  /* App Landing CSS End*/
  /* Banner CSS */
  .main-banner {
   
  }
  .main-banner .banner-img img {
    max-width: 100%;
  }
  .main-banner .banner-img::after, .main-banner .banner-img::before {
    display: none;
  }
  .main-banner .banner-text {
    padding: 0;
  }
  .main-banner .banner-text h1 {
    font-size: 50px;
  }
  .main-banner .banner-shape {
    display: none;
  }
  /* How Use CSS */
  .how-use .how-use-slider::after {
    display: none;
  }
  /* Pricing CSS */
  .pricing-section .price-card {
    padding: 50px 40px;
  }
  .pricing-section .price-card .price-feature ul {
    padding-left: 0;
  }
  .pricing-section .price-card .price-feature ul li {
    font-size: 16px;
  }
  /* Testimonial Section */
  .testimonial-section .testimonial-img .client-img :nth-child(1) {
    top: 50px;
    left: 80px;
    max-width: 60px;
  }
  .testimonial-section .testimonial-img .client-img :nth-child(2) {
    bottom: 30px;
    left: 28%;
  }
  .testimonial-section .testimonial-img .client-img :nth-child(4) {
    left: 0;
    right: 0;
    max-width: 60px;
    margin: auto;
    top: 80px;
  }
  /* Footer CSS */
  .footer-area .footer-widget h3 {
    margin-bottom: 30px;
  }
  .footer-area .footer-widget ul li {
    font-size: 15px;
  }
  .footer-area .copyright-area {
    margin-top: 70px;
  }
  /* App landing CSS End*/
  /* Sass Landing CSS  Start */
  /* Banner CSS */
  .banner-style-two {
    height: 100%;
  }
  .banner-style-two .banner-text {
    padding: 150px 0 140px;
    max-width: 550px;
  }
  .banner-style-two .banner-text h1 {
    font-size: 50px;
  }
  .banner-style-two .banner-image-two :nth-child(1) {
    bottom: 80px;
    max-width: 500px;
  }
  .banner-style-two .banner-image-two :nth-child(2) {
    bottom: 95px;
    max-width: 90px;
  }
  .banner-style-two .banner-image-two :nth-child(3) {
    display: none;
  }
  /* Why Choose CSS */
  .why-choose-two .shapes {
    display: none;
  }
  /* Standard CSS */
  .standard-section .standard-shape {
    bottom: 200px;
  }
  /* Our Apps */
  .our-apps .apps-shape {
    display: none;
  }
  /* Counter CSS */
  .counter-section .counter-text h2 {
    font-size: 25px;
  }
  .counter-section .counter-text p {
    font-size: 13px;
  }
  .counter-section .counter-img :nth-child(1) {
    bottom: 0px;
    max-width: 400px;
    right: 0;
  }
  .counter-section .counter-img :nth-child(2) {
    right: 80px;
    bottom: 0px;
    max-width: 100px;
  }
  /* Pricing Section */
  .price-section-two {
    padding-top: 130px;
  }
  /* Footer CSS */
  .footer-style-two .pl-90 {
    padding-left: 20px;
  }
  /* Sass Landing CSS  End */
  /* Sass Landing Page Two CSS Start */
  /* Banner CSS */
  .main-banner-two .banner-image-three {
    max-width: 490px;
  }
  /* Feature CSS */
  .features-style-two {
    padding-bottom: 70px;
  }
  /* Keep CSS */
  .keep-update {
    padding-bottom: 0;
  }
  .keep-update .keep-shape {
    display: none;
  }
  /* Price CSS */
  .price-style-two {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .price-style-two .price-card .price-feature ul li {
    font-size: 16px;
  }
  .price-style-two .price-card .price-feature ul li i {
    font-size: 12px;
  }
  .testimonial-style-two .testimonial-slider-area .testimonial-items .client-text {
    font-size: 15px;
  }
  /* Footer CSS */
  .footer-style-three {
    padding-top: 230px;
  }
  /* Sass Landing Page Two CSS End */
  /* Personal Portfolio Two CSS Start */
  /* Banner CSS */
  .banner-style-four .banner-text {
    padding-top: 70px;
  }
  /* About CSS */
  .about-section .about-text h2 {
    font-size: 30px;
  }
  .about-section .about-text ul li {
    height: 45px;
  }
  .about-section .about-text ul li i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    left: -4px;
    font-size: 14px;
  }
  /* Software CSS */
  .software-section {
    padding-bottom: 70px;
  }
  /* Footer CSS */
  .footer-style-four {
    padding-bottom: 70px;
  }
  .footer-style-four .footer-widget .footer-logo {
    margin-bottom: 0;
  }
  .footer-style-four .footer-widget .footer-logo img {
    margin-bottom: 15px;
  }
  .footer-style-four .footer-widget p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .footer-style-four .footer-widget .footer-btn a {
    margin-bottom: 10px;
    margin-right: 0;
    padding: 10px 14px;
  }
  .footer-style-four .pl-90 {
    padding-left: 40px;
  }
  /* Personal Portfolio Two CSS End */
  /* Creative Agency CSS Start */
  /* Banner  CSS */
  .banner-style-three {
    height: 100%;
  }
  .banner-style-three .banner-text {
    padding: 150px 0;
  }
  .banner-style-three .banner-text p {
    font-size: 15px;
  }
  /* Service CSS */
  .service-style-four .service-card {
    padding: 30px;
  }
  /* Process CSS */
  .work-process .process-card {
    height: 125px;
  }
  .work-process .process-card::before {
    top: 6px;
  }
  .work-process .process-card h3 {
    padding-top: 35px;
  }
  /* Case Stuudy CSS */
  .case-study-section .case-study-slider .case-items .case-study-text h3 a {
    font-size: 20px;
  }
  /* Testimonial CSS */
  .testimonial-style-four {
    padding-top: 70px;
  }
  /* Pricing CSS */
  .pricing-section .price-card {
    padding: 20px;
  }
  .pricing-section .price-card .price-feature {
    padding: 0 20px;
  }
  /* Team CSS */
  .team-section {
    padding-bottom: 100px;
  }
  .team-section .team-slider .team-card {
    width: 100%;
  }
  /* Footer Style Five */
  .footer-style-five {
    padding-top: 230px;
  }
  /* Creative Agency CSS End */
}

@media (min-width: 1799px) {
  /* App Landing CSS Start */
  .main-banner {
    height: 1000px;
  }
  .main-banner .banner-shape :nth-child(1) {
    top: 0;
    left: -100px;
    width: 245px;
  }
  .main-banner .banner-shape :nth-child(2) {
    left: 100px;
  }
  .main-banner .banner-shape :nth-child(3) {
    right: 150px;
    top: 100px;
  }
  /* App Landing CSS End */
  /* Sass Landing CSS Start */
  /* Banner CSS Start */
  .main-banner .banner-image-two :nth-child(1) {
    bottom: 100px;
    right: 0;
    max-width: 900px;
    z-index: 1;
  }
  .main-banner .banner-image-two :nth-child(2) {
    bottom: 100px;
    right: 50px;
  }
  .main-banner .banner-image-two :nth-child(3) {
    bottom: 55%;
  }
  /* Counter CSS Start */
  .counter-section .counter-img :nth-child(1) {
    right: 175px;
  }
  .counter-section .counter-img :nth-child(2) {
    right: 195px;
    bottom: 0px;
    max-width: 150px;
  }
  /* Sass Landing CSS End */
  /* Sass Landing Page Two CSS Start */
  .main-banner-two .banner-image-three {
    max-width: 700px;
  }
  /* Sass Landing Page Two CSS End */
  /* Personal Portfoli Page CSS Start */
  .testimonial-style-three .testimonial-area::after {
    width: 40%;
  }
  /* Personal Portfoli Page CSS End */
}
