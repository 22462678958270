/* .leaderboard{} */
.list-table-view{
    display: flex;
    padding: 10px;
    justify-content: space-evenly;
    border-bottom: 1px solid #e4e0ff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    width: 80%;
    margin: auto;
}
.list-table-view p{
    padding: 0px;
    margin: 0px;
}
.leaderboard-username-heading,
.leaderboard-score-heading,
.leaderboard-rank-heading{
    font-weight: 700;
    
}
.user-profile,
.leaderboard-score,
.leaderboard-rank{
    display: flex;
    margin: 0;
    padding: 7px 7px 7px;
}
.username-value-for-leaderboard{
    cursor: pointer;
}
.username-value-for-leaderboard:hover{
    border-radius: 15px;
    background: #7d69ff;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.user-profile i{
    padding-top: 7px;
    padding-right: 7px;
    color: #7d69ff;
}
.user-profile p{
    width: 207px;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.leaderboard-rank,
.leaderboard-score{
    width:75px;
    padding-left: 10px;
}
/* user overview */
.leaderboard-user-overview{
    /* width: 80%; */
    margin: auto;
    margin-top: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;   */
}
.user-overview-headings{
    display: flex;
    justify-content: space-between;
}

.user-overview-headings p{
    padding: 10px;
    margin: 0;
}
.user-score-overview{
    display: flex;
}
.user-position{
    
    border-right: 1px solid #e4e0ff;
}
.user-position,.user-score{
    padding: 0px 14px 10px;
    margin-bottom: 5px;
    
}
.user-score p,.user-position p{
    margin: 0;
    font-size: 14px;
}
.user-score p.score-value, .user-position p.position-value{
    font-weight: 700;
    color: black;
    text-align: center;
}

/* .user-overview-chart{ */
    /* background: #cac2ff; */
    /* height: 100px; */
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
/* } */
.list-table-view-btn{
    display: flex;
    justify-content: center;
}
.list-table-view-btn button{
    padding: 10px;
    border-radius: 15px;
    color: #7d69ff;

   
}


/*  */
@media (max-width: 767px) {
    .list-table-view{
        width: 100%;
        justify-content: space-between;
    }
    .user-profile,
.leaderboard-score,
.leaderboard-rank{
        padding: 0px;
    }
    ul{
        margin: 0;
    }
    .user-profile p{
        text-align: left;
        width: 180px;
    }
    /*  */
.leaderboard-user-overview{
    width:100%;
}
}