@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.dashboard-container {
  width: 100%;
  margin: auto;
  height: max-content;
  background: #000;
}
.top-banner-title-box {
  text-align: center;
  padding: 55px;
  font-family: Poppins;
  /* background: #2c2c2c; */
  height: 450px;

  background: #252930;
  border-radius: 12px;
  border-bottom: 5px solid rgba(255, 255, 255, 0.3); /* Semi-transparent border */
  -webkit-mask-image: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  mask-image: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(5px);
}

.content-text {
  position: absolute;
  width: 100%;
  /* background: #000; */
  top: 180px;
  filter: none;
}
.content-text h1.title-text-for-dashboard {
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  width: 50%;
  margin: auto auto auto;
  padding: 25px;
  z-index: 9;
  filter: blur(0px);
  /* position: absolute; */
}
.card-grid-showcase {
  display: grid; /* Enable grid layout */
  place-items: center;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Responsive columns */
  gap: 60px; /* Add spacing between cards */
  padding: 0px;
  padding-top: 0px;
}
.card-grid-showcase .dashboard-grid-card {
  position: relative;
  top: -20px;
  padding: 20px;
  padding-top: 2px;
  width: 500px;
  background: #13161b;
  border-radius: 10px;
  height: 300px;
}
.dashboard-grid-card .grid-card-title-text {
  color: #fff;
  font-weight: 700;
  padding: 14px;
  padding-bottom: 0px;
  text-align: center;
}

.practcode-dashboard-showcase {
  width: 60%;
  margin: auto;
  /* background: #252930; */
  display: flex;
  justify-content: space-between;
}
.practcode-dashboard-showcase .element-1,
.practcode-dashboard-showcase .element-2,
.practcode-dashboard-showcase .element-3 {
  /* background: #252930; */
  padding: 14px;
}

.element-1 h1,
.element-2 h1,
.element-3 h1 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}

.element-1 i,
.element-2 i,
.element-3 i {
  font-size: 40px;
  color: #b5b5b5;
}

.element-1,
.element-2,
.element-3 {
  text-align: center;
}
.card-course-content-heading{
  align-items: center;
  color: #fff;
  padding: 10px;
}
.card-course-content-text{
  color: #3b3b3b;
  width: 90%;
  margin: auto;
}
.card-course-content-counter{
  color: #8e8e8e;
}
.card-course-continue-btn-section{
  width: 90%;
  margin: auto;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.card-course-continue-btn{
  width: 80%;
  background: rgb(121, 121, 214);
  border-radius: 5px;
  padding: 5px;
}
.card-course-continue-btn a.card-course-continue-link{
  color: white;
  font-weight: 700;
}



/* card codeEditor for user  */
/* From Uiverse.io by reshades */
.Codemaster-card {
  width: 90%;
  height: 253px;
  background: transparent;
  border-radius: 10px;
  border: 1px #212121 solid;
  margin: auto;
  margin-top: auto;
  margin-top: 20px;
  box-shadow: rgba(44, 44, 182, 0.25) 0px 6px 12px -2px,
    rgba(18, 18, 18, 0.3) 0px 3px 7px -3px;
}

.Codemaster-card hr {
  color: #313131;
  margin-top: -3.5px;
}

.Codemaster-card-content {
  color: #8e8e8e;
  margin-left: 0.3em;
}

.c1 {
  color: rgb(55, 91, 158);
}

.c2 {
  color: rgb(55, 158, 150);
}

.c3 {
  color: rgb(158, 151, 55);
}

.c4 {
  color: rgb(88, 158, 55);
}

.Codemaster-card-comment {
  opacity: 50%;
}

.Codemaster-card-title {
  color: #e8e8e8;
  font-size: small;
  float: left;
  margin-left: 0.6em;
  margin-top: 0.6em;
}

.Codemaster-card-top {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 21px;
  font-weight: 450;
  background-color: #121212;
  width: 100%;
  text-align: right;
  border-radius: 10px;
}

.Codemaster-card-topfix {
  background-color: #121212;
  width: 100%;
  height: 1em;
  margin-top: -1em;
}

button.codemaster-card-btn {
  width: 40px;
  height: 35px;
  margin-left: -5px;
  border: 0;
  outline: 0;
  background: transparent;
  transition: 0.2s;
}

button.codemaster-card-btn svg path,
button.codemaster-card-btn svg rect,
button.codemaster-card-btn svg polygon {
  fill: #ffffff;
}

button.codemaster-card-btn svg {
  width: 10px;
  height: 10px;
}

.close:hover {
  background-color: #ffffff10;
}

.maximize:hover {
  background-color: #ffffff10;
}

.minimize:hover {
  background-color: #ffffff10;
}

.codemaster-try-now-section{
 
  width: 95%;
  margin: auto;
  margin-top: 20px;
  text-align: center;
}
.codemaster-try-now-section button.codemaster-try-now-btn{
  width: 80%;
  background: rgb(121, 121, 214);
  border-radius: 5px;
  padding: 5px;
}
.codemaster-try-now-btn a.try-now-btn-link{
  color: white;
  font-weight: 700;
}


/*  */
/* progress-graphs-section-1  */
.progress-graphs-section-1{
  border-radius: 5px;
  border: 1px solid rgb(44, 44, 67);
  padding: 14px;
  width: 95%;
  margin: auto;
  box-shadow: rgba(44, 44, 182, 0.25) 0px 6px 12px -2px, rgba(18, 18, 18, 0.3) 0px 3px 7px -3px;
}
.progress-graphs-section-1 .diplay-panel-for-linegraph-1{
  background: rgb(121, 121, 214);
  /* padding: 5px; */
  text-align: center;
  width: 220px;
  margin: auto;
  border-radius: 5px;
}
.diplay-panel-for-linegraph-1 .panel-for-linegraph-1-text{
  color: white;
  font-weight:700 ;
  padding: 10px 0px 10px;
}

.practcode-courses-avialable-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

@media (max-width: 768px) {
  .practcode-courses-avialable-section {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .practcode-courses-avialable-section {
    grid-template-columns: 1fr;
  }
}

/*  */

@media (max-width: 1024px) {
  .content-text h1.title-text-for-dashboard {
    font-size: 40px;
    width: 100%;
  }
  .card-grid-showcase {
    width: 90%;
    margin: auto;
  }
  .card-grid-showcase .dashboard-grid-card {
    width: 95%;
    box-shadow: rgba(189, 189, 189, 0.25) 0px 13px 27px -5px,
      rgba(55, 71, 92, 0.3) 0px 8px 16px -8px;
  }
  .practcode-dashboard-showcase {
    display: block;
  }
}
