.MainEditor-tabs{
    display: flex;
    justify-content: space-between;
    /* background: ; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.codeRun-btn{
    margin: 2px 10px 2px;
    border: 1px solid rgb(128, 128, 128);
    padding: 5px;
    border-radius: 5px;
}

.Editor-container{
    display: flex;
}
.output-tab{
    display: flex;
}
.output-tab button{
    background: #7D69FF;
    color: white;
    padding: 0px 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-left: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.loader-gif{
   
}
.output-div .pl{
   padding-left: 0px; 
}
.mt1{
    margin-top: 10px;
}