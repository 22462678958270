.Internal-quiz{
    width: 90%;
    /* padding: 14px; */
    /* background: #e2deff; */
    margin: auto;
}


/*quiz content */
.quiz-Options {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Adjust as needed */
    grid-gap: 20px; /* Adjust the gap between boxes */
  }
  
  .Option-box {
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    padding: 14px;
    box-sizing: border-box;
    text-align: left;
  }
  .Option-box:hover{
    background: #7d69ff;
    color: #fff;
    border:none;
  }
/* ----------- */